import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import { LanguageContext } from '../../../LanguageContext';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import MetaTag from "../../../components/yugioh/MetaTag"
import ProductFullWidthListSection from "../../../components/ProductFullWidthListSection"
import AdsContainer from '../../../components/AdsContainer'
import { useParams } from 'react-router-dom';
import { getYugiohData } from '../../../services'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
export default function ArcheTypesDetailPage() {
    const { archetypeName } = useParams();
    const [cardDetailData, setCardDetailData] = useState(null);
    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const { lang } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }

    useEffect(() => {
        getYugiohData('archetype-detail', '', '', '', '', '', '', '', '', archetypeName).then((responseData) => {
            if (responseData) {
                setCardDetailData(responseData.data)
            }
        });
    }, []);

    const pageAlias = 'archetypeDetail'


    const landingArray = [
        { name: pageMapping[globalLang]['archetypes'], landing: `/yugioh/${globalLang}/archetypes` },
        { name: archetypeName, landing: `/yugioh/${globalLang}/archetypes/${archetypeName}` },
    ];

    let description = `Explore the ${archetypeName} - Yu-Gi-Oh! Archetype Detail on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides the card information for ${archetypeName}. Stay informed about the card details in the ${archetypeName} meta and understand their impact on deck-building and gameplay strategies. Whether you're a competitive player or simply curious about the ${archetypeName}, digitalgamebase.com is your go-to resource. Visit now to access the most up-to-date information on the {archetypeName} and enhance your Yu-Gi-Oh! gameplay.`
    if (globalLang == 'zh-tw') {
        description = `在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「${archetypeName} - 主題系列資料」。這個頁面提供了官方卡牌遊戲{archetypeName}相關的卡牌資料。了解${archetypeName}相關的卡牌，並了解它們對卡組構築和遊戲策略的影響。無論您是競技玩家還是對 ${archetypeName}相關卡牌感興趣，digitalgamebase.com 是您的首選資源。立即訪問，獲取最新的${archetypeName}卡牌資訊，提升您的遊戲王玩法。`
    }
    const meta = MetaTag({ prefixTitle: `${archetypeName} - `, pageAlias: pageAlias, replacedDescription: description, extraKeywords: `${archetypeName}, `, globalLang: globalLang, extraCanonical: `archetypes/${archetypeName}` });

    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <div className='flex'>
                        <BreadcrumbsWithIcon landingArray={landingArray} />
                    </div>
                    <section className='mt-4'>
                        <div className="w-full ">
                            {
                                cardDetailData !== null &&
                                <section className="mt-2">
                                    <ProductFullWidthListSection lang={lang} data={cardDetailData} />
                                </section>
                            }

                        </div>
                    </section>
                </div>




            </div>
        </DocumentMeta >
    )
}
