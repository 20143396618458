import React, { useContext, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Checkbox,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
} from "@material-tailwind/react";

import { LanguageContext } from '../../LanguageContext';
import {

    MagnifyingGlassIcon,

} from "@heroicons/react/24/outline";
import { commonItems, searchCardItems, searchInputItems } from './YugiohData';
import { useNavigate } from 'react-router-dom';

export default function SearchForm({ customIcon = '' }) {
    const { globalLang } = useContext(LanguageContext);
    let language = 'en';
    if (globalLang && ['en', 'zh', 'zh-tw'].includes(globalLang)) {
        language = globalLang;
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(!open);
    const [checkboxValues, setCheckboxValues] = useState({});
    const [queryString, setQueryString] = useState('');

    const handleCheckboxChange = (event) => {
        const { id, checked, type, value } = event.target;

        setCheckboxValues((prevValues) => {
            let updatedValues = { ...prevValues };

            if (type === 'checkbox') {
                updatedValues = {
                    ...prevValues,
                    [id]: checked,
                };
            } else if (type === 'radio') {
                updatedValues = {
                    ...Object.fromEntries(Object.entries(prevValues).filter(([key]) => !key.startsWith(id.split('-')[0]))),
                    [id]: value,
                };
            }

            const regroupedData = Object.entries(updatedValues).reduce((result, [key, value]) => {
                if (value) {
                    const [groupName, item] = key.split('-');
                    result[groupName] = result[groupName] || [];

                    result[groupName].push(item);
                }
                return result;
            }, {});

            const queryStrings = Object.entries(regroupedData)
                .map(([key, values]) => `${encodeURIComponent(key)}=${values.join(',')}`)
                .join('&');
            setQueryString(queryStrings);

            return updatedValues;
        });
    };
    const navigate = useNavigate();

    const submitForm = () => {
        navigate(`/yugioh/en/cards?${queryString}`);
        setOpen(!open)
        window.location.reload();

    };
    const handleChange = (e) => {
        const value = e.target.value;
        // Check if the input is a number and not exceeding 5 characters
        if (/^\d+$/.test(value) && value.length <= 5) {
            // Handle the valid input here
        } else {
            // Prevent non-integer input and limit to 5 characters
            e.target.value = value.slice(0, 5).replace(/\D/g, '');
        }
    };

    return (
        <>
            {customIcon != '' ?
                React.createElement(customIcon, {
                    strokeWidth: 2,
                    className: "h-6 text-black w-6 my-auto cursor-pointer",
                    onClick: handleOpen
                })
                : <Input onClick={handleOpen} color='white' className='' icon={<MagnifyingGlassIcon className="h-5 w-5 text-theme-color-4 " />} label={commonItems[language]['search']} />
            }

            <Dialog size="xl" open={open} handler={handleOpen}>
                <DialogHeader className='text-xl'>Filter</DialogHeader>
                <DialogBody className='border-y-2 broder-gray'>
                    <div className='h-[380px] md:h-[560px] overflow-auto'>
                        <div className='col-span-3 md:col-span-4 grid grid-cols-2 md:grid-cols-4 gap-8 mb-4'>
                            {searchInputItems.map((item, index) => {
                                return (
                                    <div key={index} class="w-full">
                                        <div className='flex'>
                                            <label className="block mb-1 text-xs md:text-sm text-slate-800">
                                                {item.title}
                                            </label>
                                            <img className='w-6 ml-2' alt={`yugioh ${item.alias} - ${item.title}`} src={`https://www.digitalgamebase.com/images/yugioh/common/${item.alias}.png`} />

                                        </div>

                                        <div className="relative">
                                            <input
                                                className="w-full h-10 bg-transparent placeholder-text-slate-400 text-slate-700 text-sm border border-slate-200 rounded px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
                                                placeholder="0"
                                                maxLength="5"
                                                onChange={handleChange}

                                            />
                                        </div>
                                    </div>

                                );
                            })}
                        </div>
                        {searchCardItems.map((cardType, index) => (
                            <div className="grid grid-cols-5" key={index}>
                                <div className="text-blue-900 col-span-5 md:col-span-1 mt-4 text-xs md:text-sm">
                                    {cardType.title}
                                </div>
                                <List className="grid grid-cols-3 md:grid-cols-4 col-span-5 md:col-span-4">
                                    {Object.keys(cardType.items).map((item) => {
                                        const value = (cardType.items[item][globalLang])
                                        return (
                                            <div key={cardType.alias} className="p-0 overflow-x">
                                                <label htmlFor={`${cardType.alias} - ${value}`} className="flex cursor-pointer items-center px-3 py-2">
                                                    <div className="mr-3">
                                                        <input
                                                            type={cardType.type}
                                                            id={`${cardType.alias}-${item}`}
                                                            checked={checkboxValues[`${cardType.alias}-${item}`] || false}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </div>
                                                    <div className="font-medium text-xs md:text-sm">
                                                        {value}
                                                        {cardType.hasImage && (
                                                            <img
                                                                className='w-6'
                                                                alt={`yugioh ${cardType.alias} - ${value}`}
                                                                src={`https://www.digitalgamebase.com/images/yugioh/${cardType.alias}/${item}.png`}
                                                            />
                                                        )}
                                                    </div>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </List>
                            </div>
                        ))}


                    </div>

                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="gradient"
                        color="gray"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="black" onClick={submitForm}>
                        <span>Confirm</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}