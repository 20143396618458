import client from "../client"

const apiUrl = process.env.REACT_APP_API_URL;

export const getYugiohData = async (endpoint, cardList, banListDate, cardId, lang, relatedType, relatedArchetypeValue, relatedSetName, cardFilter, archetypeName, offset) => {
  try {
    const response = await fetch(`${apiUrl}/yugioh/index.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        endpoint: endpoint,
        cardList: cardList,
        banListDate: banListDate,
        cardId: cardId,
        archetypeName: archetypeName,
        lang: lang,
        relatedType: relatedType,
        relatedArchetypeValue: relatedArchetypeValue,
        relatedSetName: relatedSetName,
        cardFilter: cardFilter,
        offset: offset,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
//Yugioh
//https://db.ygoprodeck.com/api/v7/archetypes.php

//Master Duel
//https://ygoprodeck.com/api/master-duel/tier-list.php?tier=RANK&season=25
//https://ygoprodeck.com/api/master-duel/card-usage.php?format=RANK&season=25
//https://ygoprodeck.com/api/master-duel/seasons.php




export const getPostDetail = async (title) => {
  const result = client
    .fetch(
      `*[title == "${title}" || title_en == "${title}"] {
      title,
      description,
      title_en,
      description_en,
      archetype,
      cardIDs,
      body,
      body_en,
      categories[] -> {
        title,
        title_en,
        slug
      },
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      }
    }`
    )
  return result;
};

export const getPosts = async (catAlias) => {
  const filterCondition = (catAlias) ? `&& *[_type == "category" && slug.current == "${catAlias}"][0]._id in categories[]._ref` : ''
  const result = client
    .fetch(
      `
    *[_type=='post' ${filterCondition} ] | order(publishedAt desc){
      title,
      title_en,
      slug,
      categories[0]->{slug, title, description, title_en, description_en},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      publishedAt
    }
    `
    )
  return result;
};


export const getCategories = async () => {
  const result = client
    .fetch(
      `*[_type == "category"] {
        title,
        title_en,
        slug,
        alias
    }`
    )
  return result;
};


export const getLatestPosts = async (limit, isImageNeeded = false) => {
  const filterCondition = (isImageNeeded) ? "mainImage {asset -> { _id, url }, alt},publishedAt," : ""
  const result = client
    .fetch(
      `*[_type == "post"] | order(publishedAt desc) {title, title_en, mainImage {
              asset -> {
                _id,
                url
              },
              alt
            },publishedAt, ${filterCondition} categories[0]->{alias, title, title_en, slug}} [0...${limit}]`
    )
  return result;
};

export const getRelatedPosts = async ({ slug, title = '', limit = 4 }) => {
  const randomCondition = Math.floor(Math.random() * 2);
  const randomOrder = Math.floor(Math.random() * 2);
  const condition = (randomCondition === 0) ? 'title' : 'publishedAt'
  const order = (randomOrder === 0) ? 'asc' : 'desc'
  const result = client
    .fetch(
      `
          *[_type=='post' && (title != "${title}" || title_en != "${title}") && *[_type == "category" && slug.current == "${slug}"][0]._id in categories[]._ref] | order(${condition} ${order}){
            title,
            title_en,
            slug,
            categories[0]->{slug, title, title_en},
            mainImage {
              asset -> {
                _id,
                url
              },
              alt
            },
            publishedAt
          }[0..${limit}]`
    )
  return result;
};

