import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import CloudinaryImage from "./CloudinaryImage";
export default function HorizontalCard({ ranking, type, alt, cid, backupImage, title, info, href  }) {
  const cardHeaderClass = (type == 'top') ? 'm-0 w-1/5 shrink-0' : 'm-0 w-2/5 shrink-0'
  const cardMore = (type == 'top') ? 'flex items-center gap-2' : 'flex items-center gap-2 text-sm'
  const cardTitleVariant = (type == 'top') ? 'h4' : 'h6'
  const cardInfoVariant = (type == 'top') ? 'h4' : 'h6'
  return (
    <a href={href}>
      <Card className="w-full flex-row my-4">
        <CardHeader
          shadow={false}
          floated={false}
          className={cardHeaderClass}
        >
          <CloudinaryImage
            cid={cid}
            alt={alt}
            backupImage={backupImage}
            className="h-full w-full object-cover"
          />
        </CardHeader>
        <CardBody>
          <Typography variant={cardTitleVariant} color="gray" className="mb-4 uppercase">
            {title}
          </Typography>
          <Typography variant={cardInfoVariant} color="blue-gray" className="mb-2">
            {info}
          </Typography>
          {/*
          <a href="#" className="inline-block">
          <Button className={cardMore}>
             More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
        */
          }

        </CardBody>
        {type == 'top' &&
          <CardBody className="ml-auto">
            <Typography variant="h6" color="gray" className="mb-4 ">
              {ranking}
            </Typography>
          </CardBody>
        }
      </Card>
    </a>
  );
}