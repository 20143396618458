import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import { Textarea } from "@material-tailwind/react";
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { LanguageContext } from '../../../LanguageContext';
import { UserContext } from '../../../UserContext';
import CloudinaryImage from '../../../components/CloudinaryImage'
import { getYugiohData } from '../../../services'
import { getUserCustomDeck, setUserCustomDeck} from '../../../services/firebase'
import { useParams } from 'react-router-dom';
import { pageMapping } from '../../../components/yugioh/YugiohData';

import {
    PlusIcon,
    PencilSquareIcon,
    TrashIcon
  } from '@heroicons/react/24/outline'

export default function CustomDeckPage() {
    const [cardInfoData, setCardInfoData] = useState(null);
    const [deckData, setDeckData] = useState([]);
    const [deckIndex, setDeckIndex] = useState(-1);
    const [isEdit, setIsEdit] = useState(0);
    const [editingDeckName, setEditingDeckName] = React.useState('');
    const [newCardId, setNewCardId] = React.useState('');
    const { userData } = useContext(UserContext);

    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const { lang } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }

    const landingArray = [
        { name: pageMapping[globalLang]['custom-deck'], landing: `/yugioh/${globalLang}/user/custom-deck` },
    ];
    const imageSuffix = (lang != 'en') ? '_zh' : ''
    const meta = {
        title: `All Archetypes - Yu-Gi-Oh! Card - digitalgamebase.com`,
        description: 'All Archetypes - Yu-Gi-Oh! Card - digitalgamebase.com',
        canonical: `https://www.digitalgamebase.com/yugioh/zh-tw/archetypes`,
        meta: {
            title: `All Archetypes - Yu-Gi-Oh! Card - digitalgamebase.com`,
            name: {
                keywords: `yugioh, Yu-Gi-Oh! All Archetypes, Yu-Gi-Oh!`
            }
        }
    };

    const getCardList = async (index) =>  {
        if(deckData[index] !== undefined && deckData[index]?.cards) {
            let tmpCardid = deckData[index].cards.join();
            getYugiohData('card-info', '', '', '', '', '', '', '', `id=${tmpCardid}`).then((responseData) => {
                if (responseData) {
                    setCardInfoData(responseData.data)
                }
            });
        }
    }
    
    const getUserCustomDeckData = async () => {
		try {
			const response = await getUserCustomDeck(userData?.email ?userData.email : '');
			if (response.length > 0 && response[0]?.deck) {
				setDeckData(response[0].deck)
                setDeckIndex(0)
                getCardList(0)
			}
		} catch (error) {
			console.error('Error ', );
		}
	};

    const startEdit = async () => {
        setIsEdit(1)
        if(deckData[deckIndex]) setEditingDeckName(deckData[deckIndex].name)
    }

    const newDeck = async() => {
        setIsEdit(1);
        setDeckIndex(deckData.length);
        setEditingDeckName('newDeck')
        setCardInfoData(null)
    }

    const cancelEdit = async () => {
        setIsEdit(0)
        setEditingDeckName('')
        getCardList(deckIndex)
    }

    const saveEdit = async () => {
        let tmpDeckData = Object.assign(deckData)

        let newCardsId = [];
        if(cardInfoData != null) {
            cardInfoData.map(cardItem => {
                newCardsId.push(cardItem.id)
            })
        }

        if(newCardsId.length > 0) {
            let newDeckData = {
                name: editingDeckName,
                cards: newCardsId
            }
            tmpDeckData[deckIndex] = newDeckData
            let updateResult = await setUserCustomDeck(userData.email, tmpDeckData)
            if(updateResult) {
                console.log('update success')
                setIsEdit(0)
                setEditingDeckName('')
                try {
                    const response = await getUserCustomDeck(userData?.email ?userData.email : '');
                    if (response.length > 0 && response[0]?.deck) {
                        setDeckData(response[0].deck)
                        setDeckIndex(deckIndex)
                        getCardList(deckIndex)
                    }
                } catch (error) {
                    console.error('Error ', );
                }
            }else{
                console.log('error to save')
            }
        }else {
            console.log('empty deck')
        }
        
    }

    const removeCard = async (cardId) => {
        let tempCardInfoData = cardInfoData.filter(function( data ) {
            return data.id !== cardId;
        });
        setCardInfoData(tempCardInfoData)
    }
    const addNewCard = async () => {

        await getYugiohData('card-info', '', '', '', '', '', '', '', `id=${newCardId}`).then((responseData) => {
            if (responseData) {
                setCardInfoData(oldArray => [...oldArray,responseData.data[0]] );
            }
        });
        setNewCardId('')

    }

    const onEnterPress = (e) => {
        if (e.keyCode == 13){
            e.preventDefault();
            addNewCard();
        }
    }

    useEffect(() => {
        getUserCustomDeckData()
    }, []);

    useEffect(() => {
        setCardInfoData(null)
        if(deckIndex !== -1) getCardList(deckIndex)
    }, [deckIndex]);

    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <div className='flex'>
                        <BreadcrumbsWithIcon landingArray={landingArray} />
                    </div>

                    <section className='mt-4'>
                        <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-4">
                            <div className="min-h-[60vh] col-span-3 md:col-span-2 border-theme-color-1 border-2 rounded-lg p-2">
                                {
                                    deckData.map((data, index) => (
                                        <div key={index} className={"border-dotted border-b-2 border-black py-2 cursor-pointer " + (index === deckIndex && "text-theme-color-1 font-bold ") } onClick={() =>{cancelEdit();setDeckIndex(index); }}>{data.name}</div>
                                    ))
                                }
                                <div className="py-2 flex justify-center cursor-pointer" onClick={() => newDeck()}><PlusIcon className="text-theme-color-1 font-bold w-6 h-6" /> </div>
                                
                            </div>
                            <div className="min-h-[50vh] col-span-3 md:col-span-3 lg:col-span-6">
                                <div className="w-full grid grid-cols-3 md:grid-cols-6 gap-4">
                                    {
                                        isEdit === 0 ? 
                                        <div className="col-span-3 md:col-span-4 text-3xl text-theme-color-1 font-bold">{deckData[deckIndex] && deckData[deckIndex].name}</div>
                                        : 
                                        <Textarea
                                            rows={1}
                                            value={editingDeckName} 
                                            onChange={(e) => setEditingDeckName(e.target.value)}
                                            className="min-h-full !border-0 focus:border-transparent placeholder-gray-500 lg:py-[5px] !text-3xl"
                                            containerProps={{
                                            className: "col-span-3 md:col-span-4 grid h-full",
                                            }}
                                            labelProps={{
                                                className: "before:content-none after:content-none border-b-2 border-gray-500 ",
                                            }}
                                         />
                                    } 
                                   
                                    <div className="col-span-3 md:col-span-2 text-xl text-theme-color-1">
                                        {
                                            isEdit === 0 ? 
                                            <div className="flex justify-end gap-2">
                                                <PencilSquareIcon className="text-theme-color-1 font-bold w-6 h-6 cursor-pointer" onClick={() => startEdit()}/>
                                            </div>
                                            : 
                                            <div className="flex justify-end gap-2">
                                                <div className="rounded-md p-1 flex justify-center border-theme-color-1 border-2 cursor-pointer" onClick={() => cancelEdit()}>cancel</div>
                                                <div className="rounded-md p-1 px-2 flex justify-center bg-theme-color-1 text-white cursor-pointer" onClick={() => saveEdit()}>save</div>
                                            </div>
                                        } 
                                    </div>
                                {
                                    cardInfoData && cardInfoData.length > 0 && (
                                        cardInfoData.map((data, index) => (
                                            <div className="relative" key={index}>
                                                {
                                                    isEdit === 1 &&
                                                    <div className="absolute right-0 bg-theme-color-5 p-2 cursor-pointer" onClick={() => removeCard(data.id)}> 
                                                        <TrashIcon className="text-white my-auto font-bold w-6 h-6" /> 
                                                    </div>
                                                }  
                                                <a href={`archetypes/${data.name}`}>
                                                    <CloudinaryImage alt={`${data.name}`} cid={`yugioh/card/${data.id}${imageSuffix}`} backupImage="yugioh:card:default.jpg" />
                                                    <p>{data.name}</p>
                                                </a>
                                            </div>
                                        ))
                                    )
                                }
                                {
                                    isEdit === 1 &&
                                    <>
                                        <Textarea 
                                            rows={1}
                                            value={newCardId}
                                            onChange={(e) => setNewCardId(e.target.value)}
                                            onKeyDown={(e) => onEnterPress(e)}
                                            label="new card id"
                                        />
                                    </>
                                   
                                } 
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </DocumentMeta >
    )
}