import ReactApexChart from 'react-apexcharts';

import React, { useState, useEffect, useRef } from 'react'


export default function Chart({ data }) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data && data.updatedSeries && data.updatedLabels && data.updatedSrc) {
      setChartData({
        series: data.updatedSeries ,
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: data.updatedLabels,
          colors: ['#93C3EE', '#E5C6A0', '#669DB5', '#94A74A'],
          fill: {
            type: 'image',
            opacity: 1,
            image: {
              src: data.updatedSrc,
            },
          },
          stroke: {
            width: 4,
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#111'],
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              borderWidth: 0,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 320,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      });
    }
  }, [data]);
  return (
    <div className='max-w-full w-full'>

      {chartData ? (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="pie"
          width={450}
        />
      ) : (
        <p>Loading chart data...</p>
      )}    </div>


  )
}
