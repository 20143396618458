import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import Chart from '../../../components/Chart'
import HorizontalCard from '../../../components/HorizontalCard'
import { getYugiohData } from '../../../services'
import { Cloudinary } from '@cloudinary/url-gen';
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { useParams } from 'react-router-dom';
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { LanguageContext } from '../../../LanguageContext';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from '../../../components/AdsContainer'

export default function TopMetaArcheTypesPage() {
    const [data, setData] = useState(null);
    const [topArchetypesData, setTopArchetypesData] = useState(null);
    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const { lang } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }

    const cld = new Cloudinary({
        cloud: {
            cloudName: 'digitalgamebase'
        },
    });

    const pageAlias = 'topArchetypeList'

    const meta = MetaTag({ pageAlias: pageAlias, globalLang: globalLang, extraCanonical: `archetypes/top-meta-archetypes` });
    useEffect(() => {
        getYugiohData('top-archetypes').then((responseData) => {
            if (responseData) {
                const limitedData = responseData['archetypes'].slice(0, 5);
                //const updatedSrc = limitedData.map((data) => cld.image(`yugioh/card/${data.arch_1_img}`).delivery(defaultImage("yugioh:card:default.jpg")).toURL());
                const updatedSrc = limitedData.map((data) => `https://www.digitalgamebase.com/images/yugioh/card/${data.arch_1_img}.jpg`); const updatedSeries = limitedData.map((data) => data.quantity);
                const updatedLabels = limitedData.map((data) => data.arch_1);
                setData({ updatedSrc, updatedSeries, updatedLabels });
                setTopArchetypesData(responseData['archetypes'])
            }
        });
    }, []);

    const generateTopArchetypeImages = (placement) => {
        let slicedData = [];
        if (placement === 'top') {
            slicedData = topArchetypesData.slice(0, 3);
        } else if (placement === 'bottom') {
            slicedData = topArchetypesData.slice(3);
        }
        return slicedData.map((archetypeData, index) => (
            <div className='flex' key={index}>
                <HorizontalCard
                    type="bottom"
                    alt={`Top Archetypes ${archetypeData.arch_1}`}
                    cid={`yugioh/card/${archetypeData.arch_1_img}`}
                    backupImage="yugioh:card:default.jpg"
                    title={archetypeData.quantity}
                    info={archetypeData.arch_1}
                    href={`/yugioh/${globalLang}/archetypes/${archetypeData.arch_1}`}
                />


            </div>
        ));
    };

    const landingArray = [
        { name: pageMapping[globalLang]['archetypes'], landing: `/yugioh/${globalLang}/archetypes` },
        { name: pageMapping[globalLang]['top-archetypes'], landing: `/yugioh/${globalLang}/archetypes/top-meta-archetypes` },
    ];

    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <BreadcrumbsWithIcon landingArray={landingArray} />
                    {topArchetypesData &&
                        <section className=''>
                            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="max-w-full">
                                    <Chart data={data} />
                                </div>
                                <div className="">
                                    <div className="grid grid-rows-2 grid-flow-col gap-4">
                                        <div className="row-span-2 col-span-2 ">
                                            <div className='flex'>
                                                <HorizontalCard ranking="1st" type="top" href={`/yugioh/${globalLang}/archetypes/${topArchetypesData[0].arch_1}`} className='w-1/2' alt={`Top Archetypes ${topArchetypesData[0].arch_1}`} cid={`yugioh/card/${topArchetypesData[0].arch_1_img}`} backupImage="yugioh:card:default.jpg" title={topArchetypesData[0].quantity} info={topArchetypesData[0].arch_1} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">

                                <div className="">
                                    <div>

                                        <HorizontalCard ranking="2nd" type="top" href={`/yugioh/${globalLang}/archetypes/${topArchetypesData[1].arch_1}`} className='w-1/3' alt={`Top Archetypes ${topArchetypesData[1].arch_1}`} cid={`yugioh/card/${topArchetypesData[1].arch_1_img}`} backupImage="yugioh:card:default.jpg" title={topArchetypesData[1].quantity} info={topArchetypesData[1].arch_1} />

                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <HorizontalCard ranking="3rd" type="top" href={`/yugioh/${globalLang}/archetypes/${topArchetypesData[2].arch_1}`} className='w-1/3' alt={`Top Archetypes ${topArchetypesData[2].arch_1}`} cid={`yugioh/card/${topArchetypesData[2].arch_1_img}`} backupImage="yugioh:card:default.jpg" title={topArchetypesData[2].quantity} info={topArchetypesData[2].arch_1} />

                                    </div>
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-4">
                                {topArchetypesData && generateTopArchetypeImages('bottom')}
                            </div>
                        </section>
                    }
                </div>




            </div>
        </DocumentMeta>
    )
}
