import { List, ListItem, Card, Typography, ListItemPrefix, ListItemSuffix } from "@material-tailwind/react";
import React, { useContext, useEffect, useRef } from 'react'
import {
  CalendarDaysIcon
} from "@heroicons/react/24/outline";
import { LanguageContext } from '../LanguageContext';
import { SkeletonListItemWithoutAvatar } from "./skeleton/SkeletonListItemWithoutAvatar";
export default function ListItemWithoutAvatar({ title, data=[] }) {


  return (
    <Card className="rounded-none	px-4 pt-4">
      <Typography className='text-base ml-2 pb-2 border-b' variant="h2" color="blue-gray">
        {title}
      </Typography>
      <List >
        {data ? data.map((list, index) => (
          <a href={list.landing} key={index} className="text-sm text-initial">
            <ListItem className="pl-0 pt-2 py-1">
              <ListItemPrefix className="text-sm md:text-xs xl:text-sm">
                {list.prefix}
              </ListItemPrefix>
              <ListItemSuffix className="flex text-xs">
                <CalendarDaysIcon className="w-6 mr-2" />{list.suffix}
              </ListItemSuffix>
            </ListItem>
          </a>
        )): <>
        <SkeletonListItemWithoutAvatar/>
        <SkeletonListItemWithoutAvatar/>
        <SkeletonListItemWithoutAvatar/>
        </>} 
      </List>
    </Card>
  );
}