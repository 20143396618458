import { Typography } from "@material-tailwind/react";
import logoImg from './yugioh/images/logo.png';
import { pageMapping } from '../components/yugioh/YugiohData';
import { LanguageContext } from '../LanguageContext';
import React, { useContext } from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const { globalLang } = useContext(LanguageContext);
  let language = 'en';
  if (globalLang && ['en', 'zh', 'zh-tw'].includes(globalLang)) {
    language = globalLang;
  }

  return (
    <footer className="w-full bg-theme-color-1 p-8 ">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12  text-center md:justify-between">
        <img src={logoImg} alt="digitalgamebase.com" className="w-10" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-theme-color-5 text-theme-color-4"
            >
              {pageMapping[language]['master-duel']}
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-theme-color-5 text-theme-color-4"
            >
              {pageMapping[language]['forbidden-list']}

            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-theme-color-5 text-theme-color-4"
            >
              {pageMapping[language]['top-archetypes']}
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-theme-color-5 text-theme-color-4"
            >
              {pageMapping[language]['cards']}
            </Typography>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-blue-gray-50" />
      <Typography color="blue-gray" className="text-center font-normal text-theme-color-4">
        &copy; {currentYear} Digital Game Base
      </Typography>
    </footer>
  );
}