
import React from "react";
import CloudinaryImage from "./CloudinaryImage";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import { SkeletonListItem } from "./skeleton/SkeletonListItem";
export function ProductFullWidthListCard({
  id,
  imageSuffix,
  link,
  name,
  type,
  attribute,
  race,
  level,
  archetype,
  tcg_date,
  ocg_date,
  desc,
}) {
  const cardFields = [
    { value: type, label: 'Type' },
    { value: attribute, label: 'Attribute' },
    { value: race, label: 'Race' },
    { value: level, label: 'Level' },
    { value: archetype, label: 'Archetypes' },
    { value: tcg_date, label: 'TCG Date' },
    { value: ocg_date, label: 'OCG Date' },

  ];
  return (
    <a href={link} className="">
      <Card shadow={false} className="rounded-none my-4 w-full flex-row pb-2">
        <CardHeader
          shadow={false}
          floated={false}
          className="m-0 w-1/4 md:w-1/5 shrink-0 rounded-r-none">
          <CloudinaryImage className='mx-auto w-24 h-36 md:w-36 md:h-52 my-4 bg-gray-300' alt={`${name}`} cid={`yugioh/card/${id}${imageSuffix}`} backupImage="yugioh:card:default.jpg" />
        </CardHeader>
        <CardBody className="py-0">
          <div className="justify-between mt-4 ">
            <Typography className="mb-2 text-sm md:text-lg" color="blue-gray" variant="h3">
              {name}
            </Typography>
            <div className='grid grid-cols-3 md:grid-cols-4 gap-x-8'>
              {cardFields.map((field, index) => (
                (field.value != null &&
                  <div key={index}>
                    <Typography className="text-sm text-gray-600 mb-2 " color="blue-gray" variant="h4">
                      {field.label}
                    </Typography>
                    <Typography className="text-xs" color="blue-gray" variant="h5">
                      {field.value}
                    </Typography>
                  </div>
                )
              ))}
            </div>
            <div className="line-clamp-2 md:line-clamp-4">
              <Typography className="text-sm text-gray-600 py-4 ">
                {desc}
              </Typography>
            </div>
          </div>
        </CardBody>
      </Card>
    </a>

  );
}
export function ProductFullWidthListSection({ lang = 'en', title = '', data }) {

  const imageSuffix = (lang == 'en') ? '' : '_zh'
  return (
    <section className="">
      <div className="mx-auto text-center">
        <Typography variant="h2" className="my-2 text-xl">
          {title}
        </Typography>
      </div>
      <div className="mx-auto container">
        <div className="w-full">
          {data ? data.map(({ id,
            name,
            type,
            attribute,
            race,
            level,
            archetype,
            tcg_date,
            ocg_date,
            desc }, index) => (
            <React.Fragment key={index}>
              <ProductFullWidthListCard
                key={index}
                id={id}
                imageSuffix={imageSuffix}
                link={`/yugioh/${lang}/cards/${id}/${name}`}
                name={name}
                type={type}
                attribute={attribute}
                race={race}
                level={level}
                archetype={archetype}
                tcg_date={tcg_date}
                ocg_date={ocg_date}
                desc={desc}
              />
            </React.Fragment>
          )) : <>
            <SkeletonListItem />
            <SkeletonListItem />
            <SkeletonListItem />
            <SkeletonListItem />
            <SkeletonListItem />
            <SkeletonListItem />
          </>
          }
        </div>
      </div>
    </section >
  );
}

export default ProductFullWidthListSection;