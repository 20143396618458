import React, { useContext } from 'react';

import { useNavigate } from "react-router-dom";
import logoImg from './yugioh/images/logo.png';
import { LanguageContext } from '../LanguageContext';
import { UserContext } from '../UserContext';
import { pageMapping, masterDuelMenuItems, duelLinksMenuItems, forbiddenListMenuItems, archetypesMenuItems, cardDatabaseMenuItems, userMenuItems } from '../components/yugioh/YugiohData';
import SearchForm from './yugioh/SearchForm';
import AdsContainer from './AdsContainer';
import {
  Avatar,
  Navbar,
  Collapse,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  WrenchScrewdriverIcon,
  MagnifyingGlassIcon,
  BookmarkSquareIcon,
  BookmarkIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import {
  CircleStackIcon,
} from "@heroicons/react/24/solid";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function UserMenu({userData, setUserData}) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const { globalLang } = useContext(LanguageContext);
  const navigate = useNavigate();
  function handleNavigation(target) {
    navigate(target);
  }
  let language = 'en';
  if (globalLang && ['en', 'zh', 'zh-tw'].includes(globalLang)) {
    language = globalLang;
  }

  function onClickLogin(){
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        setUserData({uid: user.uid, displayName:user.displayName, email: user.email, photoURL: user.photoURL, token: token})
      })
      .catch((error) => {
        console.log(error)
        setUserData({})
      });
  }

  return(
      userData?.uid && userData?.displayName
      ?
      <Menu open={openMenu} handler={setOpenMenu} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="px-2 w-14 flex justify-center"
          >
            <Avatar src={userData.photoURL} alt={userData.displayName} className="w-6 h-6"/>
          </Button>
        </MenuHandler>
        <MenuList className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {
              userMenuItems[language].map(
                ({ icon, title, description, landing }, key) => (
                  <div onClick={() => { handleNavigation(`/yugioh/${language}${landing}`)}} key={key}>
                    <MenuItem className="flex items-center gap-3 rounded-lg">
                      <div className="flex items-center justify-center rounded-lg bg-theme-color-1 p-2 ">
                        {" "}
                        {React.createElement(icon, {
                          strokeWidth: 2,
                          className: "h-6 text-white w-6",
                        })}
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="flex items-center text-sm font-bold"
                        >
                          {title}
                        </Typography>
                        <Typography
                          variant="paragraph"
                          className="text-xs !font-medium text-blue-gray-500"
                        >
                          {description}
                        </Typography>
                      </div>
                    </MenuItem>
                  </div>
                )
              )
            }
          </ul>
        </MenuList>
      </Menu>
      :
      <Button className="w-full" variant="text" size="sm" color="blue-gray" onClick={onClickLogin}>
        Log In
      </Button>
  )
}

function NavListMenu({ items, label, icon, language }) {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = items.map(
    ({ icon, title, description, landing }, key) => (
      <a href={`/yugioh/${language}${landing}`} key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className="flex items-center justify-center rounded-lg bg-theme-color-1 p-2 ">
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-white w-6",
            })}
          </div>
          <div>

            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm font-bold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    ),
  );

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-theme-color-4  "
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {React.createElement(icon, {
                strokeWidth: 2,
                className: "h-6  w-6",
              })}
              {label}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                  }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                  }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  const { globalLang } = useContext(LanguageContext);
  let language = 'en';
  if (globalLang && ['en', 'zh', 'zh-tw'].includes(globalLang)) {
    language = globalLang;
  }

  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">

      <NavListMenu items={masterDuelMenuItems[language]} label={pageMapping[language]['master-duel']} icon={BookmarkSquareIcon} language={language} />
      <NavListMenu items={duelLinksMenuItems[language]} label={pageMapping[language]['duel-links']} icon={BookmarkSquareIcon} language={language} />
      <NavListMenu items={forbiddenListMenuItems[language]} label={pageMapping[language]['forbidden-list']} icon={NoSymbolIcon} language={language} />
      {/*
        <Typography
        as="a"
        href={`/yugioh/${language}/decks`}
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          {React.createElement(Square2StackIcon, {
            strokeWidth: 2,
            className: "block h-6 w-6 my-auto transition-transform ",
          })}
          Decks
        </ListItem>
      </Typography>
      */
      }

      <NavListMenu items={archetypesMenuItems[language]} label={pageMapping[language]['archetypes']} icon={BookmarkIcon} language={language} />
      <NavListMenu items={cardDatabaseMenuItems[language]} label={pageMapping[language]['cards']} icon={CircleStackIcon} language={language} />

      {/*<Typography
        as="a"
        href={`/yugioh/${language}/deck-builder`}
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          {React.createElement(WrenchScrewdriverIcon, {
            strokeWidth: 2,
            className: "block h-6 w-6 my-auto transition-transform ",
          })}
          Deck Builder
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href={`/yugioh/${language}/cards`}
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 text-theme-color-4 ">
          {React.createElement(CircleStackIcon, {
            strokeWidth: 2,
            className: "block h-6 w-6 my-auto transition-transform ",
          })}
          {pageMapping[language]['cards']}
        </ListItem>
      </Typography>
*/
      }

    </List>
  );
}

export default function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);
  const { globalLang } = useContext(LanguageContext);
  const { userData, setUserData } = useContext(UserContext);
  let language = 'en';
  if (globalLang && ['en', 'zh', 'zh-tw'].includes(globalLang)) {
    language = globalLang;
  }
  function login() {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        setUserData({ uid: user.uid, displayName: user.displayName, email: user.email, token: token })
      })
      .catch((error) => {
        console.log(error)
        /*const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);*/
      });
  }

  return (
    <div>
      <Navbar fullWidth={true} className="py-2 bg-theme-color-1 border-none">
        <div className="flex items-center justify-between text-white">
          <Typography
            as="a"
            href="/"
            variant="h6"
            className="mr-4 cursor-pointer py-1.5 lg:ml-2"
          >
            <img className='h-12' alt="digitalgamebase.com-logo" src={logoImg} />
          </Typography>
          <div className="hidden lg:block">
            <NavList />
          </div>

          <div className="hidden gap-2 lg:flex ">
            <SearchForm />
            <a className='my-auto' href="/yugioh/zh-tw/"><Typography >繁</Typography></a> <span className='my-auto'> | </span><a className='my-auto' href="/yugioh/en/"><Typography>EN</Typography></a>
            {/*
            userData?.uid && userData?.displayName
            ?
            <Typography >Welcome! {userData.displayName}</Typography>
            :
            <Button className="w-full" variant="text" size="sm" color="blue-gray" onClick={login}>
              Log In
            </Button>
  */}          <UserMenu userData={userData} setUserData={setUserData}/>

          </div>
          <IconButton
            variant="text"
            color="white"
            className="lg:hidden"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6 " strokeWidth={2} />
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
          <NavList />
          <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
            <SearchForm />
            <a href="/yugioh/zh-tw/"><Typography>繁</Typography></a> | <a href="/yugioh/en/"><Typography>EN</Typography></a>
          </div>
          <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden pt-2">
            {/*
            userData?.uid && userData?.displayName
            ?
            <Typography >Welcome! {userData.displayName}</Typography>
            :
            <Button variant="outlined" size="sm" color="blue-gray" fullWidth onClick={login}>
              Log In
            </Button>
        */}
        <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden pt-2">
          <UserMenu userData={userData} setUserData={setUserData}/>
        </div>
          </div>
        </Collapse>
      </Navbar>

      {/*
      <div className='bg-theme-color-4 py-4 '>
        <AdsContainer maxWidth={'w-4/5'} minHeight={'h-48'} />
      </div>
      */}
    </div>



  );
}
