import React from "react";

import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "@material-tailwind/react";



const TABLE_HEAD = [
  {
    head: "Set Name",
    customeStyle: "!text-left",
  },
  {
    head: "Rarity",
    customeStyle: "text-right",
  },


];
//set_name, set_code, set_rarity_code }
function Table({data}) {
  return (
    <section className="">
      <Card className="h-full w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
             Pack List
            </Typography>
            <Typography
              variant="small"
              className="text-gray-600 font-normal mt-1"
            >
              Packs that include this card
            </Typography>
          </div>

        </CardHeader>
        <CardBody className="overflow-hidden py-2">
          <table className="w-full min-w-max table-aut o">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head, customeStyle }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 ${customeStyle}`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-hidden">
              {data.map(
                (
                  {
                    set_name, set_code, set_rarity_code
                  },
                  index
                ) => {
                  const isLast = index === data.length - 1;
                  const classes = isLast
                    ? "!p-4"
                    : "!p-4 border-b border-gray-300";
                  return (
                    <tr key={index} className="">
                      <td className={classes}>
                        <div className="flex items-center gap-4 text-left">
                          <img
                            src={`https://www.digitalgamebase.com/images/yugioh/cardset/INOV.jpg`}
                            alt={set_name}
                            className="border rounded-md p-1 h-10 w-10"
                          />
                          <div>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="!font-semibold"
                            >
                              {set_name}
                            </Typography>
                            <Typography
                              variant="small"
                              className="!font-normal text-gray-600"
                            >
                              {set_code}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-normal text-gray-600 text-right"
                        >
                          {set_rarity_code}
                        </Typography>
                      </td>


                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </section>
  );
}

export default Table;
