
import { Cloudinary } from '@cloudinary/url-gen';
import React, { useState, useEffect, useRef } from 'react'

import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder,
} from '@cloudinary/react';
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
const cld = new Cloudinary({
  cloud: {
    cloudName: 'digitalgamebase'
  },
});

export default function CloudinaryImage({ className, alt, cid, backupImage }) {
  const [imgSrc, setImgSrc] = useState(`https://www.digitalgamebase.com/images/${cid}.jpg`);

  useEffect(() => {
    setImgSrc(`https://www.digitalgamebase.com/images/${cid}.jpg`);
  }, [cid]);

  const handleImageError = () => {
    if (imgSrc.includes('_zh')) {
      setImgSrc(imgSrc.replace('_zh', ''));
    } else {
      setImgSrc('https://www.digitalgamebase.com/images/yugioh/card/default.jpg');
    }
  };

  return (
    <img
      className={className}
      alt={alt}
      src={imgSrc}
      onError={handleImageError}
    />
  );
}