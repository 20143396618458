import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import Chart from '../../../components/Chart'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import TierList from '../../../components/yugioh/TierList'
import { Cloudinary } from '@cloudinary/url-gen';
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { getYugiohData } from '../../../services'
import { LanguageContext } from '../../../LanguageContext';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from '../../../components/AdsContainer'
export default function MasterDuelTierListPage() {
  const [data, setData] = useState(null);
  const [tierListData, setTierListData] = useState(null);
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'digitalgamebase'
    },
  });
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }

  const pageAlias = 'masterDuelTierList'

  const meta = MetaTag({ pageAlias: pageAlias, globalLang: globalLang, extraCanonical:'master-duel/tier-list' });

  useEffect(() => {
    getYugiohData('tier-list').then((responseData) => {
      if (responseData) {
        setTierListData(responseData)
        const limitedData = responseData.slice(0, 5);
        //const updatedSrc = limitedData.map((data) => cld.image(`yugioh/card/${data.id}`).delivery(defaultImage("yugioh:card:default.jpg")).toURL());
        const updatedSrc = limitedData.map((data) => `https://www.digitalgamebase.com/images/yugioh/card/${data.id}.jpg`);
        const updatedSeries = limitedData.map((data) => data.tournamentPower);
        const updatedLabels = limitedData.map((data) => data.name);
        setData({ updatedSrc, updatedSeries, updatedLabels });
      }
    });
  }, []);
  const landingArray = [
    { name: pageMapping[globalLang]['master-duel'], landing: `/yugioh/${globalLang}/master-duel` },
    { name: pageMapping[globalLang]['tier-list'], landing: `/yugioh/${globalLang}/master-duel/tier-list` },

  ];

  return (
    <DocumentMeta {...meta}>
      <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
        <div className='container mx-4 sm:mx-auto'>
        <BreadcrumbsWithIcon landingArray={landingArray} />

          <section className=''>
            <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
              <div className="col-span-2">
                <TierList data={tierListData} />
              </div>
              <div>
                <Chart data={data} />
              </div>
            </div>
          </section>

        </div>




      </div>
    </DocumentMeta>
  )
}
