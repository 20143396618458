import { Carousel, Typography } from "@material-tailwind/react";
import image1 from '../images/yugioh/master-duel-latest-forbidden-list.jpg';
import image2 from '../images/yugioh/yugioh-latest-information.jpg';
import { commonItems } from "../components/yugioh/YugiohData"
import React, { useContext } from 'react'
import { LanguageContext } from '../LanguageContext';
import { useParams } from 'react-router-dom';

export default function CarouselWithContent() {
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }
  return (
    <Carousel loop autoplay className="overflow-y-hidden h-full bg-black">
      <Slide image={image1} title={commonItems[globalLang]['index-cover-msg1']} landing={`/yugioh/${lang}/forbidden-list/master-duel`}/>
      <Slide image={image2} title={commonItems[globalLang]['index-cover-msg2']} landing={`/yugioh/${lang}/cards`}/>
    </Carousel>
  );
}

function Slide({ image, title, landing }) {
  return (
    <div className="relative  w-full">
      <a href={landing}>
        <img src={image} alt={title} className="h-full w-full object-cover" />
        <div className="absolute inset-y-32  grid h-full w-full place-items-center">
          <div className="text-center">
            <Typography variant="h2" color="white" className="mb-4 text-2xl">
              {title}
            </Typography>
          </div>
        </div>
      </a>

    </div>
  );
}