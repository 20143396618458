import DocumentMeta from 'react-document-meta';
import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../LanguageContext';
import { useParams } from 'react-router-dom';
import CarouselWithContent from '../../components/CarouselWithContent'
import ListItemWithAvatar from '../../components/ListItemWithAvatar'
import Gallery from '../../components/Gallery'
import ListItemWithoutAvatar from '../../components/ListItemWithoutAvatar'
import AdsContainer from '../../components/AdsContainer'
import { commonItems, forbiddenListMapping } from "../../components/yugioh/YugiohData"
import MetaTag from "../../components/yugioh/MetaTag"
import { getYugiohData, getLatestPosts, getRelatedPosts } from '../../services'
import image1 from '../../images/yugioh/top-archetype.jpg';
import image2 from '../../images/yugioh/tcg-tier-list.jpg';
import image3 from '../../images/yugioh/master-duel-tier-list.jpg';
import image4 from '../../images/yugioh/duel-links-tier-list.jpg';
import ProductListSection from '../../components/ProductListSection';
import ProductFullWidthListSection from '../../components/ProductFullWidthListSection';
import moment from 'moment';

export default function IndexPage() {
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }
  const suffix = (lang == 'en') ? '_en' : '';

  const pageAlias = 'index'
  const meta = MetaTag({ pageAlias: pageAlias, globalLang: globalLang });



  const [latestCardsetsData, setLatestCardsetsData] = useState(null);
  const [latestBanList, setLatestBanList] = useState(null);
  const [randomCardInfoData, setRandomCardInfoData] = useState(null);
  const [monthlyCardData, setMonthlyCardInfoData] = useState(null);
  const [latestArticleData, setLatestArticleData] = useState(null);
  const [randomArticleData, setRandomArticleData] = useState([]);
  useEffect(() => {
    getYugiohData('lastest-cardsets').then((responseData) => {
      if (responseData) {
        const mappingData = responseData.map(entry => {
          return {
            imgSrc: `https://www.digitalgamebase.com/images/yugioh/cardset/${entry.set_code}.jpg`,
            suffixKey1: entry.set_name,
            suffixKey2: commonItems[lang]['number-of-card'],
            suffixValue2: entry.num_of_cards,
            suffixKey3: commonItems[lang]['release-date'],
            suffixValue3: entry.tcg_date,
            landing: `/yugioh/${globalLang}/cardsets/${entry.set_code}/${entry.set_name}`
          };
        });

        setLatestCardsetsData(mappingData)
      }
    })


    getYugiohData('banListDateLatest').then((responseData) => {
      if (responseData) {

        const mappingData = Object.entries(responseData).map(([key, value]) => {
          return { prefix: key, landing: `/yugioh/${globalLang}/forbidden-list/${forbiddenListMapping[key]}`, suffix: value };
        });
        setLatestBanList(mappingData);
      }
    })
    getYugiohData('random').then((responseData) => {
      if (responseData) {
        setRandomCardInfoData(responseData.data)
      }
    });
    getYugiohData('monthlyCard').then((responseData) => {
      if (responseData) {
        setMonthlyCardInfoData(responseData.data)
      }
    });

    getLatestPosts(3).then((responseData) => {
      if (responseData) {
        const mappingData = responseData.map(entry => {
          return {
            imgSrc: entry.mainImage.asset.url,
            suffixKey1: entry[`title${suffix}`],
            suffixKey2: commonItems[lang]['category'],
            suffixValue2: entry.categories[`title${suffix}`],
            suffixKey3: commonItems[lang]['publish-date'],
            suffixValue3: moment(entry.publishedAt).format('YYYY/MM/DD'),
            landing: `/yugioh/${lang}/blog/${entry.categories.slug.current}/${entry[`title${suffix}`]}`
          };
        });

        setLatestArticleData(mappingData)
      }
    });
    getRelatedPosts({ slug: 'deck-recommend' }).then((responseData) => {
      if (responseData) {

        const mappingData = responseData.map(entry => {
          return { typeName: entry[`title${suffix}`], landing: `/yugioh/${lang}/blog/${entry.categories.slug.current}/${entry[`title${suffix}`]}`, imageLink: entry.mainImage.asset.url };
        });

        setRandomArticleData(mappingData);
      }
    });
  }, []);


  const featuredSpecial = [
    {
      imageLink: image1,
      typeName: commonItems[lang]['top-archetypes'],
      landing: `/yugioh/${lang}/archetypes/top-meta-archetypes`,
    },
    {
      imageLink: image2,
      typeName: '(Coming Soon)',
      landing: `#`,
    },
    {
      imageLink: image3,
      typeName: commonItems[lang]['master-duel-tier-list'],
      landing: `/yugioh/${lang}/master-duel/tier-list`,
    },
    {
      imageLink: image4,
      typeName: '(Coming Soon)',
      landing: `#`,
    },
  ]



  const featuredCategories = [
    {
      imageLink:
        "https://www.digitalgamebase.com/images/yugioh/card/5405694.jpg",
      typeName: commonItems[lang]['ritual'],
      landing: `/yugioh/${lang}/cards?type=ritual monster`
    },
    {
      imageLink:
        "https://www.digitalgamebase.com/images/yugioh/card/35809262.jpg",
      typeName: commonItems[lang]['fusion'],
      landing: `/yugioh/${lang}/cards?type=fusion monster`
    },
    {
      imageLink:
        "https://www.digitalgamebase.com/images/yugioh/card/44508094.jpg",
      typeName: commonItems[lang]['synchro'],
      landing: `/yugioh/${lang}/cards?type=synchro monster`
    },
    {
      imageLink:
        "https://www.digitalgamebase.com/images/yugioh/card/84013237.jpg",
      typeName: commonItems[lang]['xyz'],
      landing: `/yugioh/${lang}/cards?type=xyz monster`
    },
    {
      imageLink:
        "https://www.digitalgamebase.com/images/yugioh/card/16178681.jpg",
      typeName: commonItems[lang]['pendulum'],
      landing: `/yugioh/${lang}/cards?type=pendulum effect monster`
    },
    {
      imageLink:
        "https://www.digitalgamebase.com/images/yugioh/card/1861629.jpg",
      typeName: commonItems[lang]['link'],
      landing: `/yugioh/${lang}/cards?type=link monster`
    },
  ];


  return (
    <DocumentMeta {...meta}>
      <div className='bg-theme-color-4 py-4'>



        <div className='container mx-auto'>
          <AdsContainer extraClassName='' maxHeight='h-[200px]' customHeight='200px' adsSlotID='6251453978' adsFormat='' />

          <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  mt-4 gap-4 mx-2'>
            <div className='col-span-2 md:col-span-1 order-1'>
              <ListItemWithoutAvatar title={commonItems[lang]['latest-forbidden-list']} data={latestBanList} />
            </div>

            <div className='col-span-2 md:col-span-3 lg:col-span-2 order-2'>
              <CarouselWithContent />
            </div>
            <div className='col-span-2 md:col-span-1 order-3'>
              <ListItemWithAvatar title={commonItems[lang]['latest-article-list']} data={latestArticleData} />
            </div>
            <div className='col-span-2 md:col-span-1 order-4'>

              <ListItemWithAvatar title={commonItems[lang]['latest-card-set']} data={latestCardsetsData} />
            </div>

            <div className='col-span-2 md:col-span-3 lg:col-span-2 order-5'>
              <Gallery data={randomArticleData} col={2} mdCol={2} xlCol={2} customWidth='w-full' />
            </div>
            <div className='col-span-2 md:col-span-1 order-6'>

              <AdsContainer extraClassName='col-span-2 md:col-span-1 mx-auto'  adsSlotID='6251453978' maxHeight='auto' adsFormat='auto' />
            </div>
          </section>

          <section className='my-4 px-2'>
            <Gallery data={featuredSpecial} col={2} mdCol={4} xlCol={4} customWidth='w-full' />
          </section>
          <section className='my-4 px-2'>
            <Gallery data={featuredCategories} col={3} mdCol={6} xlCol={6} />
          </section>
          <section className='my-4 px-2'>
            <ProductFullWidthListSection lang={lang} title={commonItems[lang]['monthly-card']} data={monthlyCardData} />
          </section>

          <AdsContainer adsSlotID='6251453978' adsLayout='' adsFormat='auto' />

          <section className='my-4 px-2'>
            <ProductListSection lang={lang} title={commonItems[lang]['random-card']} data={randomCardInfoData} />
          </section>

        </div>

        <AdsContainer extraClassName='fixed top-24 right-4' isFloating={true} maxWidth='w-[160px]' adsSlotID='6251453978' customWidth='160px' customHeight='800px' adsFormat='' />

      </div>


    </DocumentMeta>
  )
}
