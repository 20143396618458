import {

  Typography,
} from "@material-tailwind/react";
export default function Gallery({ platform = 'yugioh', col = 3, mdCol = 6, xlCol = 6, data, customWidth = 'w-auto' }) {

  return (
    <section>
      <div className={`grid grid-cols-${col} md:grid-cols-${mdCol} xl:grid-cols-${xlCol} gap-4`}>

        {data && data.map(({ imageLink, typeName, landing }, index) => (
          <a href={landing}>

            <div className='text-center' key={index}>
              <img
                className={`cursor-pointer mx-auto max-w-full rounded-none ${customWidth} h-28 md:h-48 object-cover object-center`}
                src={imageLink}
                alt={typeName}
              />
              <Typography color="blue-gray" variant="h2" className='cursor-pointer text-lg'>{typeName}</Typography>
            </div>
          </a>

        ))}
      </div>
    </section>

  );
}