import { metaTag } from "./YugiohData";

const MetaTag = ({pageAlias, prefixTitle='', replacedDescription='', extraKeywords='', extraCanonical='', globalLang}) => {
  const metaTagKey = metaTag[pageAlias][globalLang]
  const metaDescription = (replacedDescription != '') ? replacedDescription : metaTagKey.description
  return {
    title: prefixTitle + metaTagKey.title + ' - ' + metaTag['commonTitle'][globalLang],
    description: metaDescription,
    canonical: `https://www.digitalgamebase.com/yugioh/${globalLang}/${extraCanonical}`,
    meta: {
      title: prefixTitle + metaTagKey.title + ' - ' + metaTag['commonTitle'][globalLang],
      name: {
        keywords: extraKeywords + metaTagKey.keywords + metaTag['commonKeywords'][globalLang]
      }
    }
  };
};

export default MetaTag;