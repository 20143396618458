
import React, { useState, useEffect, useRef } from 'react'
import { getYugiohData } from '../../services'
import CloudinaryImage from '../CloudinaryImage'
import {
  Button,
  Avatar,
  Typography
} from "@material-tailwind/react";
import { Cloudinary } from '@cloudinary/url-gen'/*
import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder,
} from '@cloudinary/react';

import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { image } from "@cloudinary/url-gen/qualifiers/source";
*/

const cld = new Cloudinary({
  cloud: {
    cloudName: 'digitalgamebase'
  },
});




export default function TierList({data}) {
console.log(data)
  const tier1 = [];
  const tier2 = [];
  const tier3 = [];
  if (data) {

    for (let i = 0; i < data.length; i++) {
      const object = data[i];
      if (object.tournamentPower > 12) {
        tier1.push(object);
      } else if (object.tournamentPower >= 7 && object.tournamentPower <= 12) {
        tier2.push(object);
      } else {
        tier3.push(object);
      }
    }


  }

  const tiers = [
    { label: "Tier 1", data: tier1 },
    { label: "Tier 2", data: tier2 },
    { label: "Tier 3", data: tier3 },
  ];
  return (
    <div className=''>

      {data ? (
        <div>
          {tiers.map((tier) => (
            <div key={tier.label}>
              <Button className='my-4'>{tier.label}</Button>
              <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
                {tier.data.map((value) => (


                  <div key={value._id} className="flex items-center gap-4 bg-theme-color-3 text-theme-color-5">
                    <CloudinaryImage className='w-1/4' alt={`Master Duel ${tier.label} - ${value.name}`} cid={`yugioh/card/${value.id}`} backupImage="yugioh:card:default.jpg"/>
                    <div className='pr-'>
                      <Typography variant="h6">{value.name}</Typography>
                      <Typography variant="small" color="gray" className="font-normal">
                      {value.tournamentPower}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          ))}
        </div>
      ) : (
        <p>Loading chart data...</p>
      )}    </div>


  )
}
