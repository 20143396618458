import { List, ListItem, ListItemPrefix, Avatar, Card, Typography } from "@material-tailwind/react";
import React from 'react'
import { SkeletonListItemWithAvatar } from "./skeleton/SkeletonListItemWithAvatar";
export default function ListItemWithAvatar({ title, data }) {


  return (
    <Card className="rounded-none	px-4 pt-4">
      <Typography className='text-base ml-2 pb-2 border-b' variant="h2" color="blue-gray">
      {title}
      </Typography>
      <List>
        {data ? data.map((info, index) => (
          <a  key={index} href={info.landing}>
            <ListItem key={index} className="pl-0">
              <ListItemPrefix>
                <Avatar className=''variant="rounded" alt={info.suffixKey1} src={info.imgSrc} onError={event => {
                  event.target.src = "https://www.digitalgamebase.com/images/yugioh/card/default.jpg"
                  event.onerror = null
                }}
                />
              </ListItemPrefix>
              <div className=" text-xs">
                <Typography variant="h3" className='text-xs'color="blue-gray">
                  {info.suffixKey1}
                </Typography>
                <Typography variant="small" color="gray" className="font-normal text-sm md:text-xs xl:text-sm">
                  {info.suffixKey2}
                  <b>{info.suffixValue2}</b>
                  <br />
                  {info.suffixKey3}
                  <b>{info.suffixValue3}</b>

                </Typography>
              </div>
            </ListItem>
          </a>

        )) : <>
        <SkeletonListItemWithAvatar/>
        <SkeletonListItemWithAvatar/>
        <SkeletonListItemWithAvatar/>
        </>}
      </List>
    </Card>
  );
}