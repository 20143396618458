import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from "./components/Header"
import Footer from "./components/Footer"
import Error from "./pages/error"
import Index from "./pages/yugioh"
import TopMetaArcheTypesPage from "./pages/yugioh/archetypes/top-meta-archetypes"
import ArcheTypesListPage from "./pages/yugioh/archetypes/archetypes-list"
import ArcheTypesDetailPage from "./pages/yugioh/archetypes/archetypes-detail"
import MasterDuelTierListPage from "./pages/yugioh/master-duel/tier-list"
import DuelLinksTierListPage from "./pages/yugioh/duel-links/tier-list"
import CardUsagePage from "./pages/yugioh/master-duel/card-usage"
import CardListPage from "./pages/yugioh/card/card-list"
import CardDetailPage from "./pages/yugioh/card/card-detail"
import CardsetListPage from "./pages/yugioh/cardset/cardset-list"
import CardsetDetailPage from "./pages/yugioh/cardset/cardset-detail"
import ForbiddenListPage from "./pages/yugioh/forbidden-list"
import BlogList from "./pages/yugioh/blog/blog-list"
import BlogDetail from "./pages/yugioh/blog/blog-detail"
import CustomDeckPage from "./pages/yugioh/user/custom-deck"
import "./index.css";
import { LanguageProvider } from './LanguageContext';
import { UserProvider } from './UserContext';
import { app, db } from './services/firebase';

function App() {

  return (
    <LanguageProvider>
    <UserProvider>
      <Router>
        <Header />
        <Routes>

          <Route path="/" element={<Navigate to="/yugioh/en" replace />} />
          <Route path="/yugioh" element={<Navigate to="/yugioh/en" replace />} />
          <Route path="/yugioh/:lang" element={<Index />} />

          <Route path="/yugioh/:lang/master-duel/tier-list" element={<MasterDuelTierListPage />} />
          <Route path="/yugioh/:lang/master-duel/card-usage" element={<CardUsagePage />} />

          <Route path="/yugioh/:lang/duel-links/tier-list" element={<DuelLinksTierListPage />} />

          <Route path="/yugioh/:lang/forbidden-list/:cardList" element={<ForbiddenListPage />} />

          <Route path="/yugioh/:lang/archetypes/top-meta-archetypes" element={<TopMetaArcheTypesPage />} />
          <Route path="/yugioh/:lang/archetypes/" element={<ArcheTypesListPage />} />
          <Route path="/yugioh/:lang/archetypes/:archetypeName" element={<ArcheTypesDetailPage />} />

          <Route path="/yugioh/:lang/cards" element={<CardListPage />} />
          <Route path="/yugioh/:lang/cards/:cardId/:cardName" element={<CardDetailPage />} />

          <Route path="/yugioh/:lang/cardsets" element={<CardsetListPage />} />
          <Route path="/yugioh/:lang/cardsets/:cardsetId/:cardsetName" element={<CardsetDetailPage />} />

          <Route path="/yugioh/:lang/blog/" element={<BlogList />} />
          <Route path="/yugioh/:lang/blog/:category" element={<BlogList />} />
          <Route path="/yugioh/:lang/blog/:category/:title" element={<BlogDetail />} />

          <Route path="/yugioh/:lang/user/custom-deck" element={<CustomDeckPage />} />

          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </UserProvider>
    </LanguageProvider>
  )
}

export default App
