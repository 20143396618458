import DocumentMeta from 'react-document-meta';
import React, { useContext } from 'react';
import Table from '../../../components/yugioh/CardUsageTable'
import CardCarousel from '../../../components/CardCarousel'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../LanguageContext';
import { pageMapping} from '../../../components/yugioh/YugiohData';
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from '../../../components/AdsContainer'
export default function CardUsagePage() {
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
      setGlobalLang(lang);
  }
  const pageAlias = 'masterDuelCardUsage'

  const meta = MetaTag({ pageAlias: pageAlias, globalLang: globalLang, extraCanonical:'master-duel/card-usage' });


  const landingArray = [
    { name: pageMapping[globalLang]['master-duel'], landing: `/yugioh/${globalLang}/master-duel` },
    { name: pageMapping[globalLang]['card-usage'], landing: `/yugioh/${globalLang}/master-duel/card-usage` },

  ];

  return (
    <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
        <div className='container mx-4 sm:mx-auto'>
        <BreadcrumbsWithIcon landingArray={landingArray} />

          <section className=''>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">

              <div className="col-span-2">
                <Table />
              </div>
              <div>
                <CardCarousel />
              </div>
            </div>
          </section>
        </div>




      </div>
    </DocumentMeta>
  )
}
