import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import { getYugiohData } from '../../../services'
import { banListMapping } from '../../../components/yugioh/YugiohData'
import { useParams } from 'react-router-dom';
import CloudinaryImage from '../../../components/CloudinaryImage'
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { Select, Option, Spinner } from "@material-tailwind/react";
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { LanguageContext } from '../../../LanguageContext';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from '../../../components/AdsContainer'

import {
  Badge,
  Typography
} from "@material-tailwind/react";
export default function ForbiddenListPage() {
  const [bannedCards, setBannedCard] = useState(null);
  const [limitedCards, setLimitedCard] = useState(null);
  const [semiLimitedCards, setSemiLimitedCard] = useState(null);
  const [limited3Cards, setLimited3Card] = useState(null);
  const { cardList } = useParams();
  const [banListDate, setBanListDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const handleCardListChange = (event) => {
    setFilterDate(event);
  };
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }

  useEffect(() => {
    getYugiohData('banListDate').then((data) => {
      if (data !== null) {
        const targetDate = data.filter((item) => item.type === banListMapping[cardList]).map((item) => item.date);
        setFilterDate(targetDate[0]);
        setBanListDate(targetDate);
      }
    });
  }, []);

  useEffect(() => {
    if (filterDate) {
      getYugiohData('banList', banListMapping[cardList], filterDate).then((responseData) => {
        const tempBannedCards = responseData.filter((card) => card.status_text === 'Banned' || card.status_text === 'Forbidden' );
        const tempLimitedCards = responseData.filter((card) => card.status_text === 'Limited' || card.status_text === 'Limited 1');
        const tempSemiLimitedCards = responseData.filter((card) => card.status_text === 'Semi-Limited' || card.status_text === 'Limited 2');
        const tempLimited3Cards = responseData.filter((card) =>  card.status_text === 'Limited 3');
        setBannedCard(tempBannedCards);
        setLimitedCard(tempLimitedCards);
        setSemiLimitedCard(tempSemiLimitedCards);
        setLimited3Card(tempLimited3Cards);
      });
    }
  }, [filterDate]);

  const pageAlias = 'forbiddenList'
  const listType = (globalLang == 'en') ? pageMapping[globalLang][cardList] + ' Forbidden & Limited List' : pageMapping[globalLang][cardList]
  let description = `Explore the ${listType} Forbidden & Limited List on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides the current Forbidden & Limited List for the {listType}. Stay informed about the restricted and banned cards in the {listType} meta and understand their impact on deck-building and gameplay strategies. Whether you're a competitive player or simply curious about the {listType} Forbidden & Limited List, digitalgamebase.com is your go-to resource. Visit now to access the most up-to-date information on the {listType} Forbidden & Limited List and enhance your Yu-Gi-Oh! gameplay.`
  if (globalLang == 'zh-tw') {
    description = `在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「${listType}」。這個頁面提供了官方卡牌遊戲最新的${listType}。了解${listType}中受限制和禁止使用的卡牌，並了解它們對卡組構築和遊戲策略的影響。無論您是競技玩家還是對${listType}感興趣，digitalgamebase.com 是您的首選資源。立即訪問，獲取最新的${listType}資訊，提升您的遊戲王玩法。`
  }
  const keywords = (globalLang == 'en') ? `${cardList} forbidden list, ${cardList} limited list, ${cardList} ban list, ` : '禁卡表, 限卡表, 禁限卡表, '
  const meta = MetaTag({ prefixTitle: `${listType} - `, pageAlias: pageAlias, replacedDescription: description, extraKeywords: `${listType}, ${keywords}`, globalLang: globalLang, extraCanonical: `forbidden-list/${cardList}` });

  const landingArray = [
    { name: pageMapping[globalLang]['forbidden-list'], landing: `/yugioh/${globalLang}/forbidden-list` },
    { name: pageMapping[globalLang][cardList], landing: `/yugioh/${globalLang}/forbidden-list/${cardList}` },

  ];

  return (
    <DocumentMeta {...meta}>
      <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
        <div className='container mx-4 sm:mx-auto'>
          <BreadcrumbsWithIcon landingArray={landingArray} />
          {bannedCards && limitedCards && semiLimitedCards && banListDate ? (
            <>
              <Select value={banListDate[0]} onChange={handleCardListChange} label="Select Version">
                {banListDate.map((date) => (
                  <Option key={date} value={date}>
                    {date}
                  </Option>
                ))}

              </Select>

              <section className=''>

                <h2 className='text-center text-2xl	my-4'>Banned</h2>
                <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 gap-4">

                  {bannedCards.map((card) => (
                    <div key={card.id}>
                      <Badge
                        content={<NoSymbolIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
                        className="bg-gradient-to-tr from-red-400 to-red-600 border-2 border-white shadow-lg shadow-black/20"
                      >
                        <a href={`../cards/${card.id}/${card.name}`}>
                          <CloudinaryImage alt={`${card.name}`} cid={`yugioh/card/${card.id}`} backupImage="yugioh:card:default.jpg" />
                        </a>

                      </Badge>

                      <Typography variant="small">{card.name}</Typography>

                    </div>
                  ))}
                </div>

                <h2 className='text-center text-2xl	my-4'>Limited</h2>
                <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 gap-4">
                  {limitedCards.map((card) => (
                    <div key={card.id}>
                      <Badge content="1" withBorder>
                        <a href={`../cards/${card.id}/${card.name}`}>

                          <CloudinaryImage alt={`${card.name}`} cid={`yugioh/card/${card.id}`} backupImage="yugioh:card:default.jpg" />
                        </a>
                      </Badge>
                      <Typography variant="small">{card.name}</Typography>
                    </div>
                  ))}
                </div>
                <h2 className='text-center text-2xl	my-4'>Semi-Limited</h2>
                <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 gap-4">
                  {semiLimitedCards.map((card) => (
                    <div key={card.id}>
                      <Badge content="2" withBorder>
                        <a href={`../cards/${card.id}/${card.name}`}>
                          <CloudinaryImage alt={`${card.name}`} cid={`yugioh/card/${card.id}`} backupImage="yugioh:card:default.jpg" />
                        </a>
                      </Badge>
                      <Typography variant="small">{card.name}</Typography>
                    </div>
                  ))}
                </div>
                <h2 className='text-center text-2xl	my-4'>Limited 3</h2>
                <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 gap-4">
                  {limited3Cards.map((card) => (
                    <div key={card.id}>
                      <Badge content="3" withBorder>
                        <a href={`../cards/${card.id}/${card.name}`}>
                          <CloudinaryImage alt={`${card.name}`} cid={`yugioh/card/${card.id}`} backupImage="yugioh:card:default.jpg" />
                        </a>
                      </Badge>
                      <Typography variant="small">{card.name}</Typography>
                    </div>
                  ))}
                </div>
                
              </section>
            </>
          ) : (
            <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 gap-4">
              {
                Array.from({ length: 27 }).map((_, index) => <div className='bg-gray-300 w-full h-48'><Spinner className='top-20 relative mx-auto' key={index} /></div>)

              }
            </div>

          )}
        </div>




      </div >
    </DocumentMeta >
  )
}
