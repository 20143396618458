import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../LanguageContext';
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import BlockContent from "@sanity/block-content-to-react"
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from "../../../components/AdsContainer";
import ListItemWithAvatar from "../../../components/ListItemWithAvatar"
import BreadcrumbsWithIcon from "../../../components/BreadcrumbsWithIcon";
import { getPostDetail, getRelatedPosts } from '../../../services';
import { commonItems, } from "../../../components/yugioh/YugiohData"
import moment from 'moment';
import { getYugiohData } from '../../../services'
import { tify } from 'chinese-conv';
import { Typography } from "@material-tailwind/react";

import CloudinaryImage from '../../../components/CloudinaryImage'
import { SkeletonBlog } from '../../../components/skeleton/SkeletonBlog';

export default function BlogDetail() {
  const { category, title } = useParams()
  const [postDetail, setPostDetail] = useState([])
  const [relatedPosts, setRelatedPosts] = useState(null)
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const [breadcrumbCategory, setBreadcrumbCategory] = useState({ name: '', landing: '' },)
  const [canonical, setCanonical] = useState('')
  const [prefixTitle, setPrefixTitle] = useState('')
  const [breadcrumbTitle, setBreadcrumbTitle] = useState({ name: '', landing: '' },)
  const [cardData, setCardData] = useState([]);

  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }
  const suffix = (lang == 'en') ? '_en' : '';

  var menu = [];
  useEffect(() => {
    getPostDetail(title).then((newPost) => {
      setPostDetail(newPost[0]);
      setBreadcrumbCategory({ name: newPost[0].categories[0][`title${suffix}`], landing: `/yugioh/${lang}/blog/${newPost[0].categories[0].slug.current}` })
      setBreadcrumbTitle({ name: newPost[0][`title${suffix}`], landing: `/yugioh/${lang}/blog/${newPost[0].categories[0].slug.current}/${newPost[0][`title${suffix}`]}` })
      setCanonical(`blog/${newPost[0].categories[0].slug.current}/${newPost[0][`title${suffix}`]}`)
      setPrefixTitle(`${newPost[0][`title${suffix}`]} - ${newPost[0].categories[0][`title${suffix}`]} - `)
      const postBody = newPost[0][`body${suffix}`];
      postBody.map((item, index) => {
        if (item.style === 'h2') {
          menu[index] = item.children[0].text;
        }
      });
      if (newPost[0].archetype != '') {
        getYugiohData('archetype-detail', '', '', '', '', '', '', '', '', newPost[0].archetype).then((responseData) => {
          if (responseData) {
            const processedData = responseData.data.map(({ id, name, desc, zh_desc, md_name, cn_name }, index) => {
              let cardName = name;
              let imageSuffix = '';
              let isEnglishOnly = false;

              if (lang === 'zh-tw') {
                if (md_name) {
                  cardName = tify(md_name);
                  imageSuffix = '_zh';
                } else if (cn_name) {
                  cardName = tify(cn_name);
                  imageSuffix = '_zh';
                } else {
                  isEnglishOnly = true;
                }
              }
              return (
                <div>
                  <CloudinaryImage className='w-[300px]  mx-auto' alt={`${cardName}`} cid={`yugioh/card/${id}${imageSuffix}`} backupImage="yugioh:card:default.jpg" />
                  <Typography className='text-base ml-2 pb-2 border-b' variant="h3" color="blue-gray">
                    {cardName}
                  </Typography>
                  <Typography className='text-base ml-2 pb-2 border-b' variant="small" color="blue-gray">
                    {desc}
                  </Typography>
                </div>
              )
            });
            setCardData(processedData);
          }
        });
      };
    }).then(() => {
      getRelatedPosts({ slug: category, title: title, limit: 2 }).then((responseData) => {
        if (responseData) {

          const mappingData = responseData.map(entry => {
            return {
              imgSrc: entry.mainImage.asset.url,
              suffixKey1: entry[`title${suffix}`],
              suffixKey2: commonItems[lang]['category'],
              suffixValue2: entry.categories[`title${suffix}`],
              suffixKey3: commonItems[lang]['publish-date'],
              suffixValue3: moment(entry.publishedAt).format('YYYY/MM/DD'),
              landing: `/yugioh/${globalLang}/blog/${entry.categories[`title${suffix}`]}/${entry[`title${suffix}`]}`
            };
          });
          setRelatedPosts(mappingData);
        }
      });

    })

  }, []);
  let numberOfH2 = 0


  const serializers = {
    types: {
      block(props) {

        switch (props.node.style) {
          case 'h1':

            return <h1 id={props.children} className="text-2xl font-extrabold my-6">{props.children}</h1>
          case 'h2':
            if (props.children[0] === '本家卡牌' || props.children[0] === 'Archetypes Cards') {

              return (
                <>
                  <h2 id={props.children} className="scroll-mt-24 text-xl font-bold text-blue-900">
                    {props.children}
                  </h2>
                  {cardData}
                </>
              );
            }
          default:

            if (props.children == '') {
              return false
            }
            return <p className="text-base text-black leading-loose">{props.children}<br /></p>
        }
      },
    }
  }
  const landingArray = [
    breadcrumbCategory, breadcrumbTitle

  ];


  const pageAlias = 'blogDetail'
  const meta = MetaTag({ prefixTitle: prefixTitle, replacedDescription: postDetail[`description${suffix}`], pageAlias: pageAlias, extraCanonical: canonical, extraKeywords: postDetail[`keywords${suffix}`], globalLang: globalLang });

  return (
    <DocumentMeta {...meta}>
      <div className='bg-theme-color-4 py-4'>
        <div className='container mx-auto'>
          <AdsContainer extraClassName='' maxHeight='h-[100px]' customHeight='100px' adsSlotID='6251453978' adsFormat='' />
          <BreadcrumbsWithIcon landingArray={landingArray} />
          <section className="">
            <div className="mx-auto block lg:flex">
              <div className='w-full bg-white p-2'>
                {postDetail.mainImage && postDetail.mainImage.asset && postDetail[`body${suffix}`] ? (
                  <>
                  <img
                    src={postDetail.mainImage.asset.url}
                    alt={postDetail.title}
                    title={postDetail.title}
                    className="w-[300px] md:w-[600px] h-auto mx-auto"
                  />
                  <BlockContent
                  blocks={postDetail[`body${suffix}`]}
                  projectId="kj5ge4ln"
                  dataset="production"
                  serializers={serializers}
                  className="break-words leading-10 text-base"
                />
                  </>
                  
                ): <SkeletonBlog/>}

             
              </div>
              <div className="ml-0 lg:ml-4 flex-none z-20 pl-0 w-full lg:w-80 xl:text-sm">
                <div className="lg:sticky relative top-24 ">
                  <ListItemWithAvatar title={commonItems[lang]['related-article-list']} data={relatedPosts} />
                  <AdsContainer extraClassName='col-span-2 md:col-span-1 mx-auto mt-4' maxWidth='w-[300px]' adsSlotID='6251453978' />

                </div>
              </div>

            </div>
          </section>
        </div>
      </div>
    </DocumentMeta>
  )
}