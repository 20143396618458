
import React from "react";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { SkeletonCard } from "./skeleton/SkeletonCard";
import CloudinaryImage from "./CloudinaryImage";
export function ProductListCard({
  link,
  img,
  name,
}) {
  return (
    <Card shadow={false} className="border border-gray-300">
      <a href={link}>
        <CardBody className="pb-0">
          <CloudinaryImage className='w-full' alt={`${img}`} cid={img} backupImage="yugioh:card:default.jpg" />
          <div className="justify-between mt-4">
            <div>
              <Typography className="mb-2 text-sm" color="blue-gray" variant="h6">
                {name}
              </Typography>
            </div>

          </div>
        </CardBody>
      </a>
    </Card>
  );
}

export function ProductListSection({ platform = 'yugioh', customDirectory = 'cards', title = '', lang = 'en', col = 2, mdCol = 6, xlCol = 6, data }) {
  return (
    <section className="">
      <div className="mx-auto text-center">
        <Typography variant="h2" className="my-2 text-xl">
          {title}
        </Typography>
      </div>
      <div className="mx-auto container">
        <div className={`grid  gap-8 grid-cols-${col} md:grid-cols-${mdCol} xl:grid-cols-${xlCol}`}>
          {data ? data.map(({ id, name, md_name }, index) => (
            <React.Fragment key={index}>
              <ProductListCard
                key={index}
                link={`/${platform}/${lang}/${customDirectory}/${id}/${name}`}
                img={`yugioh/card/${id}`}
                name= {name}
              />
            </React.Fragment>
          )) : <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </>}
        </div>
      </div>
    </section >
  );
}

export default ProductListSection;