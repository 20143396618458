import { Carousel, IconButton } from "@material-tailwind/react";
import React, { useState, useEffect, useContext } from 'react'
import { LanguageContext } from '../LanguageContext';
import CloudinaryImage from './CloudinaryImage'
import { commonItems } from "./yugioh/YugiohData"
import { useParams } from 'react-router-dom';

import { getYugiohData } from '../services'
import { Cloudinary } from '@cloudinary/url-gen';
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
export default function CardCarousel() {
  const [topCardData, setTopCardData] = useState(null);
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'digitalgamebase'
    },
  });
  useEffect(() => {
    getYugiohData('card-usage').then((responseData) => {
      setTopCardData(responseData);
    });

  }, []);


  return (
    <div className="w-2/3 mx-auto">
      <Carousel
        loop={true}
        autoplay={true}
        className=""
        prevArrow={({ handlePrev }) => (
          <IconButton
            variant="text"
            color="white"
            size="lg"
            onClick={handlePrev}
            className="!absolute top-2/4 left-4 -translate-y-2/4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            variant="text"
            color="white"
            size="lg"
            onClick={handleNext}
            className="!absolute top-2/4 !right-4 -translate-y-2/4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </IconButton>
        )}
      >
        {topCardData && topCardData.map((data, index) => (
          <div className="w-3/5 text-center mx-auto">
            <CloudinaryImage className="w-full mx-auto" alt={`${data.name} - ${commonItems[globalLang].altSuffix}`} cid={`yugioh/card/${data.id}`} backupImage="yugioh:card:default.jpg" />
          </div>
        ))}

      </Carousel>
    </div>

  );
}