import {
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Badge
} from "@material-tailwind/react";
import React, { useState, useEffect } from 'react'
import CloudinaryImage from "../CloudinaryImage";
import { cardUsageTable, rarityMapping } from "./YugiohData"
import { getYugiohData } from '../../services'

const SortableTable = ({ }) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [topCardData, setTopCardData] = useState(null);
  const updatedData = (topCardData != null) ? topCardData.map(({ duel_count, pretty_url, season, game_mode, placement, ...rest }) => rest) : null;

  useEffect(() => {
    getYugiohData('card-usage').then((responseData) => {
      setTopCardData(responseData);
    });
  }, []);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = sortColumn
    ? [...updatedData].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0; // fallback for unsupported types
    })
    : updatedData;
  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className=" flex items-center justify-between ">
          <div>

            <Typography color="gray" className="mt-1 font-normal">
              See information about all members
            </Typography>
          </div>

        </div>

      </CardHeader>
      <CardBody className=" px-0">
        <table className="w-full  text-left">
          <thead>
            <tr>
              {topCardData && Object.keys(updatedData[0]).map((column) => {
                if (column != 'id' && column != 'rarity') {
                  return (

                    <th
                      key={column}
                      className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                      onClick={() => handleSort(column)}
                    >

                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                      >
                        {cardUsageTable[column]}

                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />

                      </Typography>
                    </th>
                  );
                }
                return null;
              })}

            </tr>
          </thead>
          <tbody>
            {topCardData && sortedData.map((row, rowIndex) => (

              <tr key={rowIndex} className="even:bg-blue-gray-50/50">
                {Object.values(row).map((cell, index, key) => {

                  if (index != 1 && index != 5) {

                    return (
                      <td key={index} className="px-4 py-2">
                        
                        <div className="inline-flex">
                          {index === 0 && (
                            <Badge
                              content={rarityMapping[row.rarity].alias}
                              className="w-8 top-0 left-2 bg-gradient-to-tr from-red-400 to-red-600 border-2 border-white shadow-lg shadow-black/20"
                            >
                              <CloudinaryImage className='w-1/6' alt={`${row.name}`} cid={`yugioh/card/${row.id}`} backupImage="yugioh:card:default.jpg" />
                            </Badge>
                          )}
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal my-auto ml-2"
                          >
                            {cell}
                          </Typography>
                        </div>

                      </td>
                    )
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
    
    </Card>
  );
};

export default SortableTable;