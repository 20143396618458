import { initializeApp } from "firebase/app";
import { getFirestore, doc, collection, query, where, getDocs, updateDoc, setDoc } from "firebase/firestore";
import { firebaseConfig } from "../config/config";

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const getUserCustomDeck = async(useremail = '') => {
    let responseData = [];
    const queryResult = await getDocs(query(collection(db, 'customDeck'), where('useremail', '==', useremail)));
    if(!queryResult.empty) {
        queryResult.docs.forEach(doc => {
            responseData.push(doc.data())
        });
    }
    return responseData;
}

export const setUserCustomDeck = async(useremail = '', deckData = []) => {

    const queryResult = await getDocs(query(collection(db, 'customDeck'), where('useremail', '==', useremail)));
		if(!queryResult.empty) {
			const docRef = queryResult.docs[0].ref;
			const updateResult = await updateDoc(docRef, {useremail: useremail, deck: deckData});
			return updateResult;
		} else {
			return console.error('Cannot find match case');
		}
}

export const addDocToCollection = async(collectionName, docData) => {
	try {
		const newid = makeid(20)
		const docRef = doc(db, collectionName, newid);
		const queryResult = await setDoc(docRef, docData);
		return queryResult;
	} catch (e) {
		return console.error(e.message);
	}
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
