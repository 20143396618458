import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [globalLang, setGlobalLang] = useState('en'); 

  return (
    <LanguageContext.Provider value={{ globalLang, setGlobalLang }}>
      {children}
    </LanguageContext.Provider>
  );
};