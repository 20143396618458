import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { LanguageContext } from '../../../LanguageContext';
import MetaTag from "../../../components/yugioh/MetaTag"
import ProductFullWidthListSection from "../../../components/ProductFullWidthListSection"
import { getYugiohData } from '../../../services'
import { useParams } from 'react-router-dom';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import { useLocation } from 'react-router-dom';
import {
    Typography, Chip, Card,
    CardHeader,
    CardBody,
    Button,
    IconButton
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import SearchForm from '../../../components/yugioh/SearchForm'

export default function CardListPage() {
    const [cardInfoData, setCardInfoData] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState(null);
    const [pageRemaining, setPageRemaining] = useState(1);

    const location = useLocation();

    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const { lang } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }
    const dataArray = [
        { key: 'type', value: 'Type' },
        { key: 'attribute', value: 'Attribute' },
        { key: 'race', value: 'Race' },
        { key: 'level', value: 'Level' },
        { key: 'archetype', value: 'Archetypes' },
        { key: 'atk', value: 'Atk' },
        { key: 'def', value: 'Def' },
        { key: 'tcg_date', value: 'TCG Date' },
        { key: 'ocg_date', value: 'OCG Date' },

    ];

    const landingArray = [
        { name: pageMapping[globalLang]['cards'], landing: `/yugioh/${globalLang}/cards` },
    ];
    const imageSuffix = (lang != 'en') ? '_zh' : ''
    const pageAlias = 'cardList'

    const meta = MetaTag({ pageAlias: pageAlias, globalLang: globalLang, extraCanonical: `cards` });

    const [active, setActive] = React.useState(1);

    const getItemProps = (index) =>
    ({
        variant: active === index ? "filled" : "text",
        color: "gray",
        onClick: () => changePage(index),
    });

    const next = () => {

        if (active === pageRemaining) return;

        changePage(active + 1);
    };

    const prev = () => {
        if (active === 1) return;

        changePage(active - 1);
    };
    const currentURL = new URL(window.location.href)

    var queryParams = currentURL.search;
    if (queryParams.startsWith("?")) {
        queryParams = queryParams.substring(1);
    } function changePage(pageNo) {
        setActive(pageNo)
        getYugiohData('card-info', '', '', '', '', '', '', '', queryParams, '', (pageNo - 1) * 24).then((responseData) => {
            if (responseData) {
                setCardInfoData(responseData.data)
            }
        });
    }


    useEffect(() => {

        getYugiohData('card-info', '', '', '', '', '', '', '', queryParams).then((responseData) => {
            if (responseData) {
                setCardInfoData(responseData.data)
                setPageRemaining(responseData.meta.pages_remaining + 1)
            }
        });

        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlParams.entries());


        const renderedFilteredOptions = Object.entries(params).map(([key, values]) => (
            <div>
                <Typography variant='small' color='blue-gray' className='text-sm'>{key}</Typography>
                <div className='grid grid-cols-2 mt-2' key={key}>
                    {values.split(",").map((value) => (
                        <Chip variant="outlined" key={value} value={value} className="mr-2 mb-2 text-xs" />
                    ))}
                </div>
            </div>

        ));
        setFilteredOptions(renderedFilteredOptions)
    }, []);




    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <div className='flex'>
                        <BreadcrumbsWithIcon landingArray={landingArray} />
                        <SearchForm customIcon={AdjustmentsHorizontalIcon} />
                    </div>
                    {filteredOptions &&
                        <>
                            <Typography variant='small' className='font-bold text-base md:text-lg'>Applied Filter</Typography>
                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5'>
                                {filteredOptions}
                            </div>
                        </>

                    }


                    <div className=''>

                        <div className="flex items-center ">
                            <Button
                                variant="text"
                                className="flex items-center gap-2"
                                onClick={prev}
                                disabled={active === 1}
                            >
                                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                            </Button>
                            <div className="mx-auto flex items-center ">
                                <>
                                    <IconButton {...getItemProps(1)} key={1}>
                                        1
                                    </IconButton>
                                    {active < 4 ? (
                                        <>
                                            {[2, 3, 4].map((num) => (
                                                num <= pageRemaining &&
                                                <IconButton {...getItemProps(num)} key={num}>
                                                    {num}
                                                </IconButton>
                                            ))}
                                            {
                                                pageRemaining > 5 &&
                                                <span>...</span>

                                            }
                                        </>
                                    ) : active > pageRemaining - 3 ? (
                                        <>

                                            <span>...</span>
                                            {[pageRemaining - 3, pageRemaining - 2, pageRemaining - 1].map((num) => (
                                                <IconButton {...getItemProps(num)} key={num}>
                                                    {num}
                                                </IconButton>
                                            ))}
                                        </>
                                    ) : (
                                        <>

                                            <span>...</span>
                                            {[
                                                active - 2,
                                                active - 1,
                                                active,
                                                active + 1,
                                                active + 2,
                                            ].map((num) => (
                                                <IconButton {...getItemProps(num)} key={num}>
                                                    {num}
                                                </IconButton>
                                            ))}
                                            <span>...</span>
                                        </>
                                    )}
                                    {pageRemaining >= 5 &&
                                        <IconButton {...getItemProps(pageRemaining)} key={pageRemaining}>
                                            {pageRemaining}
                                        </IconButton>
                                    }

                                </>
                            </div>
                            <Button
                                variant="text"
                                className="flex items-center"
                                onClick={next}
                                disabled={active === pageRemaining || pageRemaining === 0}
                            >

                                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                    {
                        cardInfoData !== null &&
                        <section className="mt-2">
                            <ProductFullWidthListSection lang={lang} data={cardInfoData} />
                        </section>
                    }


                </div>




            </div>
        </DocumentMeta >
    )
}
