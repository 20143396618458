import {
  NoSymbolIcon,
  PhoneIcon,
  ArrowUturnRightIcon,
  BookmarkIcon,
  BookmarkSquareIcon,
  CircleStackIcon,
} from '@heroicons/react/24/outline'

export const commonItems = {
  en: {
    'search': 'Search',
    'random-card': 'Random Cards',
    'monthly-card': 'Latest Cards Added',
    'related-card': 'Related Cards',
    'altSuffix': 'Yugioh Card',
    'marketprice': 'Market Price',
    'card-description': 'Card Description',
    'index-cover-msg1': 'Master Duel Latest Forbidden List',
    'index-cover-msg2': 'Yugioh Latest Card Information',
    'latest-card-set': 'Latest Card Set',
    'latest-forbidden-list': 'Latest Forbidden List',
    'latest-article-list': 'Latest Article',
    'related-article-list': 'Related Article',
    'updated-on': 'updated on',
    'number-of-card': 'Number Of Card: ',
    'release-date': ' Release Date: ',
    'category': ' Category: ',
    'publish-date': ' Date: ',
    "top-archetypes": "Top Archetypes",
    "master-duel-tier-list": "Master Duel Tier List",
    "fusion": "Fusion",
    "ritual": "Ritual",
    "synchro": "Synchro",
    "xyz": "Xyz",
    "pendulum": "Pendulum",
    "link": "Link",
  },

  "zh-tw": {
    'search': '搜尋',
    'random-card': '隨機卡牌',
    'monthly-card': '最新卡牌',
    'related-card': '相關卡牌',
    'altSuffix': '遊戲王卡牌',
    'marketprice': '市場價格',
    'card-description': '卡牌效果',
    'index-cover-msg1': 'Master Duel 最新禁卡表',
    'index-cover-msg2': '遊戲王最新卡牌資訊',
    'latest-card-set': '最新卡包',
    'latest-forbidden-list': '最新禁卡列表',
    'latest-article-list': '最新文章',
    'related-article-list': '相關文章',
    'updated-on': '更新於',
    'number-of-card': '收錄卡牌數量: ',
    'release-date': ' 發售日期: ',
    'category': ' 類別: ',
    'publish-date': ' 日期: ',
    "top-archetypes": "最強主題系列",
    "master-duel-tier-list": "遊戲王：大師決鬥 等級列表",
    "fusion": "融合",
    "ritual": "儀式",
    "synchro": "同步",
    "xyz": "超量",
    "pendulum": "靈擺",
    "link": "連結",
  }

}
export const userMenuItems = {
  en: [
    {
      title: "My card deck",
      description: "My card deck",
      icon: BookmarkIcon,
      landing: "/user/custom-deck",
    },
  ],
  "zh-tw": [
    {
      title: "卡組",
      description: "卡組",
      icon: BookmarkIcon,
      landing: "/user/custom-deck",
    },
  ],
};
export const masterDuelMenuItems = {
  en: [
    {
      title: "Tier List",
      description: "Master Duel Tier Lists",
      icon: BookmarkSquareIcon,
      landing: "/master-duel/tier-list",
    },
    {
      title: "Card Usage",
      description: "Master Duel Common Card Usage",
      icon: BookmarkSquareIcon,
      landing: "/master-duel/card-usage",
    },
    {
      title: "Forbidden List",
      description: "Master Duel Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/master-duel',
    },
  ],
  "zh-tw": [
    {
      title: "等級列表",
      description: "遊戲王：大師決鬥等級列表",
      icon: BookmarkSquareIcon,
      landing: "/master-duel/tier-list",
    },
    {
      title: "常用卡牌",
      description: "遊戲王：大師決鬥常用卡牌",
      icon: BookmarkSquareIcon,
      landing: "/master-duel/card-usage",
    },
    {
      title: "禁卡列表",
      description: "遊戲王：大師決鬥禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/master-duel',
    },
  ],
};

export const duelLinksMenuItems = {
  en: [
    {
      title: "Tier List",
      description: "Duel Links Tier Lists",
      icon: BookmarkSquareIcon,
      landing: "/duel-links/tier-list",
    },
    {
      title: "Forbidden List",
      description: "Duel Links Forbidden & Limited Lists",
      icon: BookmarkSquareIcon,
      landing: "/forbidden-list/duel-links",
    },
  ],
  "zh-tw": [
    {
      title: "等級列表",
      description: "遊戲王：決鬥聯盟等級列表",
      icon: BookmarkSquareIcon,
      landing: "/duel-links/tier-list",
    },
    {
      title: "禁卡列表",
      description: "遊戲王：決鬥聯盟禁卡列表",
      icon: NoSymbolIcon,
      landing: "/forbidden-list/duel-links",
    },
  ],
};
export const archetypesMenuItems = {
  en: [
    {
      title: "Top Archetypes",
      description: "Top Archetypes",
      icon: BookmarkIcon,
      landing: "/archetypes/top-meta-archetypes",
    },
    {
      title: "All Archetypes",
      description: "All Archetypes",
      icon: BookmarkIcon,
      landing: "/archetypes",
    },
  ],
  "zh-tw": [
    {
      title: "最強主題系列",
      description: "最強主題系列",
      icon: BookmarkIcon,
      landing: "/archetypes/top-meta-archetypes",
    },
    {
      title: "所有主題系列",
      description: "所有主題系列",
      icon: BookmarkIcon,
      landing: "/archetypes",
    },
  ],
};
export const forbiddenListMenuItems = {
  en: [
    {
      title: "TCG",
      description: "TCG Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/tcg',
    },
    {
      title: "OCG",
      description: "OCG Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/ocg',
    },
    {
      title: "OCG-AE",
      description: "OCG-AE Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/ocg-ae',
    },


    {
      title: "World Championship",
      description: "World Championship Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/world-championship',
    },
    {
      title: "Master Duel",
      description: "Master Duel Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/master-duel',
    },
    {
      title: "Duel Links",
      description: "Duel Links Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/duel-links',
    },
    {
      title: "Goat Format",
      description: "Goat Format Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/goat-format',
    },
    {
      title: "Edison Format",
      description: "Edison Format Forbidden & Limited Lists",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/edison-format',
    },
  ],
  "zh-tw": [
    {
      title: "TCG",
      description: "TCG禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/tcg',
    },
    {
      title: "OCG",
      description: "OCG禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/ocg',
    },
    {
      title: "OCG-AE",
      description: "OCG-AE禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/ocg-ae',
    },
    {
      title: "遊戲王世界錦標賽",
      description: "遊戲王世界錦標賽禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/world-championship',
    },
    {
      title: "遊戲王：大師決鬥",
      description: "遊戲王：大師決鬥禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/master-duel',
    },
    {
      title: "遊戲王：決鬥聯盟",
      description: "遊戲王：決鬥聯盟禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/duel-links',
    },
    {
      title: "山羊格式",
      description: "山羊格式禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/goat-format',
    },
    {
      title: "Edison Format",
      description: "Edison Format禁卡列表",
      icon: NoSymbolIcon,
      landing: '/forbidden-list/edison-format',
    },
  ],
};
export const cardDatabaseMenuItems = {
  en: [
    {
      title: "Card Database",
      description: "Card Database",
      icon: CircleStackIcon,
      landing: "/cards",
    },
    {
      title: "Packs",
      description: "Packs",
      icon: CircleStackIcon,
      landing: "/cardsets",
    },
  ],
  "zh-tw": [
    {
      title: "卡牌大全",
      description: "遊戲王卡牌一覽",
      icon: CircleStackIcon,
      landing: "/cards",
    },
    {
      title: "卡包",
      description: "遊戲王卡包一覽",
      icon: CircleStackIcon,
      landing: "/cardsets",
    },
  ],
};
export const pageMapping = {
  en: {
    "master-duel": "Master Duel",
    "forbidden-list": "Forbidden List",
    "archetypes": "Archetypes",
    "top-archetypes": "Top Archetypes",
    "archetypes-list": "All Archetypes",
    "cards": "Card Database",
    "cardsets": "Packs",
    "tier-list": "Tier List",
    "card-usage": "Card Usage",
    "ocg": "OCG",
    "ocg-ae": "OCG-AE",
    "tcg": "TCG",
    "world-championship": "World Championship",
    "duel-links": "Duel Links",
    "goat-format": "Goat Format",
    "edison-format": "Edison Format",
    "custom-deck": "Custom Deck",
  },
  "zh-tw": {
    "master-duel": "遊戲王：大師決鬥",
    "forbidden-list": "禁卡列表",
    "archetypes": "主題系列",
    "top-archetypes": "最強主題系列",
    "archetypes-list": "所有主題系列",
    "cards": "卡牌大全",
    "cardsets": "卡組大全",
    "tier-list": "等級列表",
    "card-usage": "常用卡牌",
    "ocg": "OCG禁卡列表",
    "ocg-ae": "OCG-AE禁卡列表",
    "tcg": "TCG禁卡列表",
    "world-championship": "遊戲王世界錦標賽禁卡列表",
    "duel-links": "遊戲王：決鬥聯盟",
    "goat-format": "山羊格式禁卡列表",
    "edison-format": "Edison Format禁卡列表",
    "custom-deck": "卡組",
  },
}

export const banListMapping =
{
  tcg: "TCG",
  ocg: "OCG",
  "ocg-ae": "OCG-AE",
  "world-championship": "World Championship",
  "master-duel": "Master Duel",
  "duel-links": "Duel Links",
  "goat-format": "Goat",
  "edison-format": "Edison",
}
export const forbiddenListMapping = {
  "TCG": 'tcg',
  "OCG": 'ocg',
  "OCG-AE": 'ocg-ae',
  "World Championship": 'world-championship',
  "Master Duel": 'master-duel',
  "Duel Links": 'duel-links',
  "Edison": 'edison',
  "Goat": 'goat',
};
export const rarityMapping =
{
  "Ultra Rare": { alias: "UR", color: '#111' },
  "Super Rare": { alias: "SR", color: '#111' },
  "Rare": { alias: "R", color: '#111' },
  "Normal": { alias: "N", color: '#111' },
}
export const cardUsageTable =
{
  name: "Name",
  win_count: "Win Count",
  loss_count: "Loss Count",
  win_ratio: "Win Ratio",
}

export const commonInfo =
{
  phone: "+85267613243",
  email: "contactus@digitalgamebase.com"
}

export const commonContact = [
  { name: '聯絡客務：' + commonInfo.phone, href: 'tel:' + commonInfo.phone, icon: PhoneIcon },
  { name: '電郵：' + commonInfo.email, href: 'mailto:' + commonInfo.email, icon: ArrowUturnRightIcon },
]



export const searchCardItems = [
  {
    alias: "type",
    type: "radio",
    title: "Card Types",
    items: {
      'normal monster': { en: 'Normal', 'zh-tw': 'Normal' },
      'effect monster': { en: 'Effect', 'zh-tw': 'Effect' },
      'fusion monster': { en: 'Fusion', 'zh-tw': 'Fusion' },
      'ritual monster': { en: 'Ritual', 'zh-tw': 'Ritual' },
      'synchro monster': { en: 'Synchro', 'zh-tw': 'Synchro' },
      'xyz monster': { en: 'Xyz', 'zh-tw': 'Xyz' },
      'pendulum effect monster': { en: 'Pendulum', 'zh-tw': 'Pendulum' },
      'link monster': { en: 'Link', 'zh-tw': 'Link' },
      'spell card': { en: 'Spell', 'zh-tw': 'Spell' },
      'trap card': { en: 'Trap', 'zh-tw': 'Trap' }
    },
    hasImage: true
  },
  {

    alias: "attribute",
    type: "checkbox",
    title: "Attributes",
    items: {
      light: { en: 'Light', 'zh-tw': 'Light' },
      dark: { en: 'Dark', 'zh-tw': 'Dark' },
      water: { en: 'Water', 'zh-tw': 'Water' },
      fire: { en: 'Fire', 'zh-tw': 'Fire' },
      earth: { en: 'Earth', 'zh-tw': 'Earth' },
      wind: { en: 'Wind', 'zh-tw': 'Wind' },
      divine: { en: 'Divine', 'zh-tw': 'Divine' }
    },
    hasImage: true
  },
  {
    alias: "race",
    type: "checkbox",
    title: "Spell ‧ Trap Types",
    items: {
      normal: { en: 'Normal Spell', 'zh-tw': 'Normal Spell' },
      field: { en: 'Field', 'zh-tw': 'Field' },
      equip: { en: 'Equip', 'zh-tw': 'Equip' },
      continuous: { en: 'Continuous Spell', 'zh-tw': 'Continuous Spell' },
      'quick-play': { en: 'quick-play', 'zh-tw': 'quick-play' },
      ritual: { en: 'Ritual', 'zh-tw': 'Ritual' },
      normal: { en: 'Normal Trap', 'zh-tw': 'Normal Trap' },
      continuous: { en: 'Continuous Trap', 'zh-tw': 'Continuous Trap' },
      counter: { en: 'Counter', 'zh-tw': 'Counter' }
    },
  },
  {
    alias: "race",
    type: "checkbox",
    title: "Race",
    items:
    {
      "aqua": { "en": "Aqua", "zh-tw": "Aqua" },
      "beast": { "en": "Beast", "zh-tw": "Beast" },
      "beast-warrior": { "en": "Beast-Warrior", "zh-tw": "Beast-Warrior" },
      "creator-god": { "en": "Creator-God", "zh-tw": "Creator-God" },
      "cyberse": { "en": "Cyberse", "zh-tw": "Cyberse" },
      "dinosaur": { "en": "Dinosaur", "zh-tw": "Dinosaur" },
      "divine-beast": { "en": "Divine-Beast", "zh-tw": "Divine-Beast" },
      "dragon": { "en": "Dragon", "zh-tw": "Dragon" },
      "fairy": { "en": "Fairy", "zh-tw": "Fairy" },
      "fiend": { "en": "Fiend", "zh-tw": "Fiend" },
      "fish": { "en": "Fish", "zh-tw": "Fish" },
      "insect": { "en": "Insect", "zh-tw": "Insect" },
      "machine": { "en": "Machine", "zh-tw": "Machine" },
      "plant": { "en": "Plant", "zh-tw": "Plant" },
      "psychic": { "en": "Psychic", "zh-tw": "Psychic" },
      "pyro": { "en": "Pyro", "zh-tw": "Pyro" },
      "reptile": { "en": "Reptile", "zh-tw": "Reptile" },
      "rock": { "en": "Rock", "zh-tw": "Rock" },
      "sea serpent": { "en": "Sea Serpent", "zh-tw": "Sea Serpent" },
      "spellcaster": { "en": "Spellcaster", "zh-tw": "Spellcaster" },
      "thunder": { "en": "Thunder", "zh-tw": "Thunder" },
      "warrior": { "en": "Warrior", "zh-tw": "Warrior" },
      "winged beast": { "en": "Winged Beast", "zh-tw": "Winged Beast" },
      "wyrm": { "en": "Wyrm", "zh-tw": "Wyrm" },
      "zombie": { "en": "Zombie", "zh-tw": "Zombie" }
    }
    ,
    hasImage: true

  },


];

export const searchInputItems = [
  {
    alias: 'atk',
    title: 'ATK'
  },
  {
    alias: 'def',
    title: 'DEF'
  },
  {
    alias: 'level',
    title: 'Level'
  },
  {
    alias: 'link',
    title: 'Link'



  },
]
export const metaTag = {
  commonTitle: {
    en: 'digitalgamebase.com | Yugioh Card Database',
    "zh-tw": 'digitalgamebase.com | 遊戲王卡庫及資訊平台',
  },
  commonKeywords: {
    en: 'card game, yugioh, yugioh card, Yu-Gi-Oh!, digitalgamebase',
    "zh-tw": '卡牌遊戲, 遊戲王, 遊戲王卡, Yu-Gi-Oh!, digitalgamebase',
  },
  index: {
    en: {
      title: 'Yu-Gi-Oh! Card',
      description: 'Digital Game Base provides various gaming information. This page including  Yu-Gi-Oh! card list and details, providing the latest information of Mster Duel and Duel Links. The most updated forbidden and limited list of Yu-Gi-Oh! can be found here. You can search whatever you want about yugioh card in this card database.',
      keywords: '',
    },
    "zh-tw": {
      title: '遊戲王卡',
      description: 'Digital Game Base 提供各種遊戲資訊。本頁面包含《遊戲王》卡片清單和詳細資訊，提供最新的《遊戲王：大師決鬥》及《遊戲王：決鬥聯盟》資訊。您可以在此找到最新的《遊戲王》禁限卡清單，更可以在這個卡片資料庫中搜尋任何有關《遊戲王》卡片的內容。',
      keywords: '',
    },
  },
  cardList: {
    en: {
      title: 'Yu-Gi-Oh! Card List',
      description: 'Discover a vast collection of Yu-Gi-Oh! trading cards at the Digital Game Base. From classic favorites to the latest releases, our website offers a comprehensive resource for Yu-Gi-Oh! enthusiasts. Immerse yourself in strategic gameplay and build your ultimate deck with the help of our user-friendly interface. Visit us now at digitalgamebase.com to embark on your Yu-Gi-Oh! card collecting journey.',
      keywords: 'yugioh card list, ',
    },
    "zh-tw": {
      title: '遊戲王卡表',
      description: '在Digital Game Base上探索一個龐大的遊戲王交換卡牌收藏。以中文版本瀏覽我們的網站，探索多樣化的遊戲王卡牌世界。我們的網站提供了一個全面的資源，供遊戲王愛好者使用。透過我們使用者友好的界面，深入投入戰略性遊戲，並建立您的終極卡牌組合。立即訪問digitalgamebase.com，開啟您的遊戲王卡牌收藏之旅。',
      keywords: '遊戲王卡表, ',
    },

  },

  cardDetail: {
    en: {
      title: 'Yu-Gi-Oh! Card Detail',
      description: '',
      keywords: '',
    },
    "zh-tw": {
      title: '遊戲王資料',
      description: '',
      keywords: '',
    },
  },

  cardsetList: {
    en: {
      title: 'Yu-Gi-Oh! Pack List',
      description: 'Explore the Yu-Gi-Oh! Pack List on digitalgamebase.com, your ultimate Yugioh card database. This page provides detailed information about various packs of Yu-Gi-Oh! cards, including different editions and series. Whether you are a novice or a seasoned player, this database will help you understand the contents of different Yu-Gi-Oh! card packs. Through this page, you can plan your purchasing and collecting strategies and stay up-to-date with the latest Yu-Gi-Oh! card releases. Visit digitalgamebase.com now to delve into the Yu-Gi-Oh! Pack List.',
      keywords: 'yugioh packs, ',
    },
    "zh-tw": {
      title: '遊戲王卡包列表',
      description: '在 digitalgamebase.com 的遊戲王卡片資料庫中，探索遊戲王！卡包列表。這個網頁提供了詳細的遊戲王卡包資訊，包含各種版本和系列的卡牌。無論你是新手還是老手，這個資料庫將幫助你了解不同的遊戲王卡包，以及它們所包含的卡片內容。透過這個網頁，你可以計畫你的購買和收藏策略，並掌握最新的遊戲王卡片發行資訊。立即訪問 digitalgamebase.com，深入瞭解遊戲王！卡包列表',
      keywords: '遊戲王卡包, 遊戲王卡包列表, ',
    },

  },

  cardsetDetail: {
    en: {
      title: 'Yu-Gi-Oh! Pack Detail',
      description: '',
      keywords: 'yugioh packs, ',
    },
    "zh-tw": {
      title: '遊戲王卡包資料',
      description: '',
      keywords: '遊戲王卡包, 遊戲王卡包列表, ',
    },

  },

  topArchetypeList: {
    en: {
      title: 'Yu-Gi-Oh! Top Archetype List',
      description: 'Explore the "Yu-Gi-Oh! Top Archetype List" on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides a comprehensive list of all the archetypes in Yu-Gi-Oh!. Whether you are a beginner or an experienced player, this platform will help you understand the characteristics and card compositions of various theme series in the game. Through our information platform, you can explore detailed information about each theme series, including an overview of the theme, representative cards, and related strategies. Visit digitalgamebase.com now to embark on your journey through the fascinating world of all theme series in Yu-Gi-Oh!.',
      keywords: 'yugioh top archetype list, ',
    },
    "zh-tw": {
      title: '最強主題系列',
      description: '在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「最強主題系列」。這個頁面提供了遊戲王中所有主題系列的列表。不論你是新手還是資深玩家，這個平台將幫助你瞭解遊戲王中各種主題系列的特點和卡片組成。透過我們的資訊平台，你可以探索各個主題系列的詳細資訊，包括主題系列的主題概述、代表卡片和相關策略。立即訪問 digitalgamebase.com，開始探索遊戲王中所有主題系列的精彩世界。',
      keywords: '最強主題系列, ',
    },

  },

  archetypeList: {
    en: {
      title: 'Yu-Gi-Oh! Archetype List',
      description: 'Explore the "Yu-Gi-Oh! Archetype List" on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides a comprehensive list of all the archetypes in Yu-Gi-Oh!. Whether you are a beginner or an experienced player, this platform will help you understand the characteristics and card compositions of various theme series in the game. Through our information platform, you can explore detailed information about each theme series, including an overview of the theme, representative cards, and related strategies. Visit digitalgamebase.com now to embark on your journey through the fascinating world of all theme series in Yu-Gi-Oh!.',
      keywords: 'yugioh archetype list, ',
    },
    "zh-tw": {
      title: '所有主題系列列表',
      description: '在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「所有主題系列列表」。這個頁面提供了遊戲王中所有主題系列的列表。不論你是新手還是資深玩家，這個平台將幫助你瞭解遊戲王中各種主題系列的特點和卡片組成。透過我們的資訊平台，你可以探索各個主題系列的詳細資訊，包括主題系列的主題概述、代表卡片和相關策略。立即訪問 digitalgamebase.com，開始探索遊戲王中所有主題系列的精彩世界。',
      keywords: '所有主題系列列表, ',
    },

  },

  archetypeDetail: {
    en: {
      title: 'Yu-Gi-Oh! Archetype Detail',
      description: '',
      keywords: 'yugioh archetype, ',
    },
    "zh-tw": {
      title: '主題系列資料',
      description: '',
      keywords: '主題系列列表, ',
    },

  },

  masterDuelTierList: {
    en: {
      title: 'Master Duel Tier List - Yu-Gi-Oh! Card Mobile Game',
      description: 'Explore the "Yu-Gi-Oh! Master Duel Level List" on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides a detailed list of the different Master Duel levels in Yu-Gi-Oh!. Whether you are a novice or a seasoned player, this platform will help you understand the characteristics and advantages of each Master Duel level. Through our information platform, you can stay updated with the latest information on Master Duel levels and learn about the dominant levels in the competitive Yu-Gi-Oh! scene. Visit digitalgamebase.com now to embark on your journey through the Yu-Gi-Oh! Master Duel levels.',
      keywords: 'master duel, master duel tier list, ',
    },
    "zh-tw": {
      title: '遊戲王：大師決鬥等級列表',
      description: '在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「遊戲王：大師決鬥等級列表」。這個頁面提供了遊戲王中各個大師決鬥等級的詳細列表。無論你是新手還是資深玩家，這個平台將幫助你瞭解不同大師決鬥等級的特點和優勢。透過我們的資訊平台，你可以掌握大師決鬥等級的最新資訊，並瞭解在遊戲王的競技場上具有主導地位的等級。立即訪問 digitalgamebase.com，開啟你的遊戲王大師決鬥之旅。',
      keywords: '遊戲王：大師決鬥, 遊戲王：大師決鬥等級列表, ',
    },

  },

  masterDuelCardUsage: {
    en: {
      title: 'Master Duel Card Usage - Yu-Gi-Oh! Card Mobile Game',
      description: 'Explore the "Yu-Gi-Oh! Master Duel Common Cards Usage" on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides a list of commonly used cards in Master Duel. Whether you are a novice or an experienced player, this platform will help you understand which cards are prevalent and competitive in Master Duel. Through our information platform, you can delve into detailed information about the commonly used cards in Master Duel, including their effects, strategic applications, and deck synergies. Visit digitalgamebase.com now to explore the world of commonly used cards in Yu-Gi-Oh! Master Duel.',
      keywords: 'master duel, master duel card usage, ',
    },
    "zh-tw": {
      title: '遊戲王：大師決鬥常用卡牌',
      description: '在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「遊戲王：大師決鬥常用卡牌」。這個頁面提供了在大師決鬥中常見且廣泛使用的卡牌列表。無論你是新手還是資深玩家，這個平台將幫助你了解在大師決鬥中哪些卡牌是常見且具有競爭力的。透過我們的資訊平台，你可以探索大師決鬥常用卡牌的詳細資訊，包括卡牌效果、戰略應用和卡組搭配。立即訪問 digitalgamebase.com，探索遊戲王大師決鬥中常用卡牌的世界。',
      keywords: '遊戲王：大師決鬥, 遊戲王：大師決鬥常用卡牌, ',
    },

  },

  duelLinksTierList: {
    en: {
      title: 'Duel Links Tier List - Yu-Gi-Oh! Card Mobile Game',
      description: 'Explore the "Yu-Gi-Oh! Duel Links Tier List" on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides a comprehensive list of the different levels in Yu-Gi-Oh! Duel Links. Whether you are a novice or an experienced player, this platform will help you understand the characteristics and requirements of each level in Duel Links. Through our information platform, you can stay updated with the latest information on Duel Links levels and learn about the challenges and rewards associated with each level. Visit digitalgamebase.com now to embark on your journey through the Yu-Gi-Oh! Duel Links levels.',
      keywords: 'duel links, duel links tier list, ',
    },
    "zh-tw": {
      title: '遊戲王：決鬥聯盟等級列表',
      description: '在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「遊戲王：決鬥聯盟等級列表」。這個頁面提供了遊戲王：決鬥聯盟中不同等級的全面列表。無論您是初學者還是經驗豐富的玩家，這個平台將幫助您了解決鬥聯盟中每個等級的特點和要求。通過我們的資訊平台，您可以隨時了解決鬥聯盟等級的最新資訊，並瞭解每個等級所帶來的挑戰和獎勵。立即訪問 digitalgamebase.com，開始您的遊戲王：決鬥聯盟等級之旅。',
      keywords: '遊戲王：決鬥聯盟, 遊戲王：決鬥聯盟等級列表, ',
    },

  },
  forbiddenList: {
    en: {
      title: 'Yu-Gi-Oh! Card Ban List',
      description: '',
      keywords: '',
    },
    "zh-tw": {
      title: '遊戲王禁卡表',
      description: '',
      keywords: '禁卡表, 限卡表, 禁限卡表,',
    },
  },
  blogList: {
    en: {
      title: 'Yu-Gi-Oh!',
      description: '',
      keywords: '',
    },
    "zh-tw": {
      title: '遊戲王',
      description: '',
      keywords: '',
    },
  },
  blogDetail: {
    en: {
      title: 'Yu-Gi-Oh!',
      description: '',
      keywords: '',
    },
    "zh-tw": {
      title: '遊戲王',
      description: '',
      keywords: '',
    },
  },
}
