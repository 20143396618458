import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { LanguageContext } from '../../../LanguageContext';
import CloudinaryImage from '../../../components/CloudinaryImage'
import { getYugiohData } from '../../../services'
import { useParams } from 'react-router-dom';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import { useLocation } from 'react-router-dom';
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from '../../../components/AdsContainer'
import {
    Typography, Chip, Card,
    CardHeader,
    CardBody,
    Button,
    IconButton
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function CardsetDetailPage() {
    const [cardInfoData, setCardInfoData] = useState(null);
    const [pageRemaining, setPageRemaining] = useState(1);

    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const { lang } = useParams();
    const { cardsetId } = useParams();
    const { cardsetName } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }
    const dataArray = [
        { key: 'type', value: 'Type' },
        { key: 'attribute', value: 'Attribute' },
        { key: 'race', value: 'Race' },
        { key: 'level', value: 'Level' },
        { key: 'archetype', value: 'Archetypes' },
        { key: 'atk', value: 'Atk' },
        { key: 'def', value: 'Def' },
        { key: 'tcg_date', value: 'TCG Date' },
        { key: 'ocg_date', value: 'OCG Date' },

    ];
    const landingArray = [
        { name: pageMapping[globalLang]['cards'], landing: `/yugioh/${globalLang}/cards` },
        { name: pageMapping[globalLang]['cardsets'], landing: `/yugioh/${globalLang}/cardsets` },
        { name: cardsetName, landing: `/yugioh/${globalLang}/cardsets/${cardsetId}/${cardsetName}` },
    ];
    const imageSuffix = (lang != 'en') ? '_zh' : ''
    const pageAlias = 'cardsetDetail'

    let description = `Explore the ${cardsetName}  - Yu-Gi-Oh! Archetype Detail on digitalgamebase.com, your ultimate Yu-Gi-Oh! card database and information platform. This page provides the card information for {cardsetName}. Stay informed about the card details in the ${cardsetName} meta and understand their impact on deck-building and gameplay strategies. Whether you're a competitive player or simply curious about the ${cardsetName}, digitalgamebase.com is your go-to resource. Visit now to access the most up-to-date information on the ${cardsetName} and enhance your Yu-Gi-Oh! gameplay.`
    if (globalLang == 'zh-tw') {
        description = `在 digitalgamebase.com 的遊戲王卡庫及資訊平台上，探索「${cardsetName} - 遊戲王卡包資料」。這個頁面提供了官方卡牌遊戲${cardsetName}相關的卡牌資料。了解${cardsetName}相關的卡牌，並了解它們對卡組構築和遊戲策略的影響。無論您是競技玩家還是對${cardsetName}相關卡牌感興趣，digitalgamebase.com 是您的首選資源。立即訪問，獲取最新的${cardsetName}卡牌資訊，提升您的遊戲王玩法。`
    }


    const meta = MetaTag({ prefixTitle: `${cardsetName} - `, pageAlias: pageAlias, replacedDescription:description, extraKeywords: `${cardsetName}, `, globalLang: globalLang, extraCanonical:`cardsets/${cardsetId}/${cardsetName}` });
    const [active, setActive] = React.useState(1);

    const getItemProps = (index) =>
    ({
        variant: active === index ? "filled" : "text",
        color: "gray",
        onClick: () => changePage(index),
    });

    const next = () => {

        if (active === pageRemaining) return;

        changePage(active + 1);
    };

    const prev = () => {
        if (active === 1) return;

        changePage(active - 1);
    };

    function changePage(pageNo) {
        setActive(pageNo)
        getYugiohData('card-info', '', '', '', '', '', '', '', `cardset=${encodeURIComponent(cardsetName)}`, '', (pageNo - 1) * 24).then((responseData) => {
            if (responseData) {
                setCardInfoData(responseData.data)
            }
        });
    }
    useEffect(() => {
        getYugiohData('card-info', '', '', '', '', '', '', '', `cardset=${encodeURIComponent(cardsetName)}`).then((responseData) => {
            if (responseData) {
                setCardInfoData(responseData.data)
                setPageRemaining(responseData.meta.pages_remaining + 1)
            }
        });
    }, []);




    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <div className='flex'>
                        <BreadcrumbsWithIcon landingArray={landingArray} />
                    </div>

                    <div className=''>

                        <div className="flex items-center ">
                            <Button
                                variant="text"
                                className="flex items-center gap-2"
                                onClick={prev}
                                disabled={active === 1}
                            >
                                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                            </Button>
                            <div className="mx-auto flex items-center ">
                                <>
                                    <IconButton {...getItemProps(1)} key={1}>
                                        1
                                    </IconButton>
                                    {active < 4 ? (
                                        <>
                                            {[2, 3, 4].map((num) => (
                                                num <= pageRemaining &&
                                                <IconButton {...getItemProps(num)} key={num}>
                                                    {num}
                                                </IconButton>
                                            ))}
                                            {
                                                pageRemaining > 5 &&
                                                <span>...</span>

                                            }
                                        </>
                                    ) : active > pageRemaining - 3 ? (
                                        <>

                                            <span>...</span>
                                            {[pageRemaining - 3, pageRemaining - 2, pageRemaining - 1].map((num) => (
                                                <IconButton {...getItemProps(num)} key={num}>
                                                    {num}
                                                </IconButton>
                                            ))}
                                        </>
                                    ) : (
                                        <>

                                            <span>...</span>
                                            {[
                                                active - 2,
                                                active - 1,
                                                active,
                                                active + 1,
                                                active + 2,
                                            ].map((num) => (
                                                <IconButton {...getItemProps(num)} key={num}>
                                                    {num}
                                                </IconButton>
                                            ))}
                                            <span>...</span>
                                        </>
                                    )}
                                    {pageRemaining >= 5 &&
                                        <IconButton {...getItemProps(pageRemaining)} key={pageRemaining}>
                                            {pageRemaining}
                                        </IconButton>
                                    }

                                </>
                            </div>
                            <Button
                                variant="text"
                                className="flex items-center"
                                onClick={next}
                                disabled={active === pageRemaining || pageRemaining === 0}
                            >

                                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>

                    <section className='mt-4'>
                        <div className="w-full ">
                            {
                                cardInfoData !== null &&
                                cardInfoData.map((cardInfo, cardInfoIndex) => (
                                    <a key={`card${cardInfoIndex}`} href={`../../cards/${cardInfo.id}/${cardInfo.name}`}>
                                        <Card className="w-full flex-row shadow-md my-4">
                                            <CardHeader
                                                shadow={false}
                                                floated={false}
                                                className="m-0 w-1/4 md:w-2/5 shrink-0 rounded-r-none"
                                            >
                                                <div className='mx-auto w-24 h-36 md:w-36 md:h-52 my-4 bg-gray-300'>
                                                    <CloudinaryImage className='' alt={`${cardInfo.name}`} cid={`yugioh/card/${cardInfo.id}${imageSuffix}`} backupImage="yugioh:card:default.jpg" />
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <Typography variant="h1" color="blue-gray" className="text-xl md:text-2xl mb-4">
                                                    {cardInfo.name}
                                                </Typography>

                                                <div className='grid grid-cols-3 md:grid-cols-5'>
                                                    {dataArray.map((data, index) => (
                                                        <React.Fragment key={`cardInfo${cardInfoIndex}${index}`}>
                                                            {cardInfo[data.key] !== "" && typeof (cardInfo[data.key]) !== "undefined" && <div key={`card${index}`} >
                                                                <Typography variant="h2" color="blue-gray" className="mt-2 font-normal text-sm md:text-base">
                                                                    {data.value}
                                                                </Typography>
                                                                <Typography variant="h3" color="blue-gray" className="font-normal text-base md:text-xl">
                                                                    {cardInfo[data.key]}
                                                                </Typography>
                                                            </div>}

                                                        </React.Fragment>
                                                    ))}
                                                </div>

                                                <Typography color="gray" className="mt-4 mb-8 font-normal">
                                                    {cardInfo.desc}
                                                </Typography>
                                            </CardBody>
                                        </Card>
                                    </a>
                                ))

                            }
                        </div>
                    </section>
                </div>




            </div>
        </DocumentMeta >
    )
}
