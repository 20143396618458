import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../LanguageContext';
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import MetaTag from "../../../components/yugioh/MetaTag"
import Gallery from "../../../components/Gallery"
import BreadcrumbsWithIcon from "../../../components/BreadcrumbsWithIcon";
import { getPosts } from '../../../services';
import AdsContainer from "../../../components/AdsContainer";
export default function BlogList() {
  const [posts, setPosts] = useState([])
  const [breadcrumbCategory, setBreadcrumbCategory] = useState({ name: '', landing: '' },)
  const { category } = useParams()
  const { globalLang, setGlobalLang } = useContext(LanguageContext);
  const [articleData, setArticleData] = useState([]);
  const [canonical, setCanonical] = useState('')
  const { lang } = useParams();
  if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
    setGlobalLang(lang);
  }
  const suffix = (lang == 'en') ? '_en' : '';

  useEffect(() => {
    getPosts(category).then((responseData) => {
      if (responseData) {
        setPosts(responseData);
        setBreadcrumbCategory({ name: responseData[0].categories[`title${suffix}`], landing: `/yugioh/${lang}/blog/${responseData[0].categories.slug.current}` })
        setCanonical(`blog/${responseData[0].categories.slug.current}`)
        const mappingData = responseData.map(entry => {
          return { typeName: entry[`title${suffix}`], landing: `/yugioh/${globalLang}/blog/${entry.categories.slug.current}/${entry[`title${suffix}`]}`, imageLink: entry.mainImage.asset.url };
        });
        setArticleData(mappingData);
      }
    });
  }, [category]);

  let landingArray = [
    breadcrumbCategory,
  ];

  const pageAlias = 'blogList'
  const meta = MetaTag({ pageAlias: pageAlias, prefixTitle: `${breadcrumbCategory.name} - `, extraCanonical: (canonical != '') ? canonical : 'blog', globalLang: globalLang });
  return (
    <DocumentMeta {...meta}>
      <div className='bg-theme-color-4 py-4'>
        <div className='container mx-auto'>
          <AdsContainer extraClassName='' maxHeight='h-[100px]' customHeight='100px' adsSlotID='6251453978' adsFormat='' />
          <BreadcrumbsWithIcon landingArray={landingArray} />
          <section className='my-4 px-2'>
            <Gallery data={articleData} col={1} mdCol={3} xlCol={3} customWidth='w-full' />
          </section>
        </div>
      </div>
    </DocumentMeta>
  )
}
