import { useState, useEffect } from 'react';
import AdSense from 'react-adsense';

export default function AdsContainer({ isFloating = false, maxWidth = 'w-full', maxHeight = 'h-[250px]', customWidth = 'auto', customHeight = 'auto', extraClassName = '', adsSlotID, adsLayout, adsFormat = 'fluid' }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {

    if (isFloating) {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

  }, []);

  return (
    <div className={`${maxWidth} ${maxHeight} ${extraClassName} text-center`} >
      <AdSense.Google
        client='ca-pub-8965678723704510'
        slot={adsSlotID}
        style={{ display: windowWidth < 1650 && isFloating ? 'none' : 'block', width:customWidth, height:customHeight }}
        layout={adsLayout}
        format={adsFormat}
        responsive='true'
      />
    </div>
  );
}