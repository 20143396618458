import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import { LanguageContext } from '../../../LanguageContext';
import CloudinaryImage from '../../../components/CloudinaryImage'
import { getYugiohData } from '../../../services'
import { useParams } from 'react-router-dom';
import { pageMapping } from '../../../components/yugioh/YugiohData';
import MetaTag from "../../../components/yugioh/MetaTag"
import { useLocation } from 'react-router-dom';
import { Spinner} from "@material-tailwind/react";
import AdsContainer from '../../../components/AdsContainer'
export default function CardsetListPage() {
    const [cardsetInfoData, setCardsetInfoData] = useState(null);
    const location = useLocation();

    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const { lang } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }

    const landingArray = [
        { name: pageMapping[globalLang]['cards'], landing: `/yugioh/${globalLang}/cards` },
        { name: pageMapping[globalLang]['cardsets'], landing: `/yugioh/${globalLang}/cardsets` },
    ];
    const imageSuffix = (lang != 'en') ? '_zh' : ''
    const pageAlias = 'cardsetList'

    const meta = MetaTag({ pageAlias: pageAlias, globalLang: globalLang, extraCanonical:'cardsets' });

    useEffect(() => {
        getYugiohData('cardsets').then((responseData) => {
            if (responseData) {
                setCardsetInfoData(responseData)
            }
        });
    }, []);





    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <div className='flex'>
                        <BreadcrumbsWithIcon landingArray={landingArray} />
                    </div>

                    <section className='mt-4'>
                        <div className="w-full grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-4">
                            {
                                cardsetInfoData && cardsetInfoData.length > 0 ? (
                                    cardsetInfoData.map((data, index) => (
                                        <a key={index} href={`cardsets/${data.set_code}/${data.set_name}`}>
                                            <CloudinaryImage alt={`${data.set_name}`} cid={`yugioh/cardset/${data.set_code}${imageSuffix}`} backupImage="yugioh:card:default.jpg" />
                                            <p>{data.set_name}</p>
                                        </a>
                                    ))
                                ) : (
                                    Array.from({ length: 24 }).map((_, index) => <div className='bg-gray-300 w-full h-48'><Spinner className='top-20 relative mx-auto' key={index} /></div>)
                                )
                            }
                        </div>
                    </section>
                </div>




            </div>
        </DocumentMeta >
    )
}
