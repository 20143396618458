import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useContext } from 'react'
import {

    Typography,

} from "@material-tailwind/react";
import { LanguageContext } from '../../../LanguageContext';
import { commonItems, pageMapping } from '../../../components/yugioh/YugiohData';
import { useParams } from 'react-router-dom';
import CloudinaryImage from '../../../components/CloudinaryImage'
import ProductListSection from '../../../components/ProductListSection'
import Table from '../../../components/Table'
import { getYugiohData } from '../../../services'
import BreadcrumbsWithIcon from '../../../components/BreadcrumbsWithIcon'
import MetaTag from "../../../components/yugioh/MetaTag"
import AdsContainer from '../../../components/AdsContainer'

import { tify } from 'chinese-conv';
import { SkeletonListItem } from '../../../components/skeleton/SkeletonListItem';

export default function CardDetailPage() {
    const { cardId } = useParams();
    const [cardDetailData, setCardDetailData] = useState(null);
    const [randomCardData, setRandomcardData] = useState(null);
    const { globalLang, setGlobalLang } = useContext(LanguageContext);
    const [cardName, setCardName] = useState('');
    const [imageSuffix, setImageSuffix] = useState('');
    const [description, setDescription] = useState('Yu-Gi-Oh! Card - digitalgamebase.com');
    const [isEnglishOnly, setIsEnglishOnly] = useState(false);
    const { lang } = useParams();
    if (lang && ['en', 'zh', 'zh-tw'].includes(lang)) {
        setGlobalLang(lang);
    }

    useEffect(() => {
        const fetchData = async () => {
            var responseData = await getYugiohData('card-detail', '', '', cardId, lang);
            if (responseData) {
                responseData = responseData[0]
                setCardName(responseData.name)
                setDescription(responseData.desc + ` - digitalgamebase.com`)

                if (lang == 'zh-tw') {
                    responseData.desc = tify(responseData.zh_desc)
                    setDescription(tify(responseData.zh_desc) + ` - digitalgamebase.com`)
                    if (responseData.md_name != '') {
                        setCardName(tify(responseData.md_name))
                        setImageSuffix('_zh')

                    } else if (responseData.cn_name != '') {
                        setCardName(tify(responseData.cn_name))
                        setImageSuffix('_zh')
                    } else {
                        setIsEnglishOnly(true)
                    }
                }

                setCardDetailData(responseData)

                const relatedType = (responseData.archetype) ? 'archetype' : 'set_name'
                const relatedArchetypeValue = (responseData.archetype) ? responseData.archetype : ''
                const relatedSetName = responseData.card_sets
                getYugiohData('relatedCard', '', '', cardId, '', relatedType, relatedArchetypeValue, relatedSetName).then((responseCardData) => {
                    if (responseCardData) {
                        console.log(responseCardData)
                        setRandomcardData(responseCardData)
                    }
                });
            }
        }

        fetchData();
    }, []);


    const dataArray = [
        { key: 'type', value: 'Type' },
        { key: 'attribute', value: 'Attribute' },
        { key: 'race', value: 'Race' },
        { key: 'level', value: 'Level' },
        { key: 'archetype', value: 'Archetypes' },
        { key: 'atk', value: 'Atk' },
        { key: 'def', value: 'Def' },
        { key: 'tcg_date', value: 'TCG Date' },
        { key: 'ocg_date', value: 'OCG Date' },

    ];
    const dataPriceArray = [
        { key: 'amazon_price', value: 'Amazon Price' },
        { key: 'cardmarket_price', value: 'Card Market Price' },
        { key: 'ebay_price', value: 'Ebay Price' },
        { key: 'coolstuffinc_price', value: 'CoolStuffInc ' },
        { key: 'tcgplayer_price', value: 'TCG player Market' },
    ];

    const landingArray = [
        { name: pageMapping[globalLang]['cards'], landing: `/yugioh/${globalLang}/cards` },
        { name: cardName, landing: `/yugioh/${globalLang}/cards/${cardId}/${cardName}` },
    ];

    const pageAlias = 'cardDetail'
    const meta = MetaTag({ prefixTitle: `${cardName} - `, replacedDescription: description, extraKeywords: `${cardName}, `, pageAlias: pageAlias, globalLang: globalLang, extraCanonical: `cards/${cardId}/${cardName}` });

    return (
        <DocumentMeta {...meta}>
            <div className='container-fluid flex bg-theme-color-4 min-h-screen pb-8'>
                <div className='container mx-4 sm:mx-auto'>
                    <BreadcrumbsWithIcon landingArray={landingArray} />

                    {isEnglishOnly &&
                        <p>暫時只有英文版資料</p>
                    }
                    <section className='mt-4'>
                        <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
                            {cardDetailData ?
                                <>
                                    <div className="w-full">
                                        <CloudinaryImage className='w-[180px] mx-auto md:mx-0' alt={`${cardName}`} cid={`yugioh/card/${cardDetailData.id}${imageSuffix}`} backupImage="yugioh:card:default.jpg" />
                                    </div>
                                    <div className="w-full col-span-2">
                                        <Typography
                                            variant="h2"
                                            color="blue-gray"
                                            className="font-normal text-base md:text-lg mt-2"
                                        >
                                            {cardName}
                                        </Typography>
                                        <div className="w-full grid grid-cols-4 gap-4">
                                            {dataArray.map((data, index) => (
                                                <React.Fragment key={index}>
                                                    {cardDetailData[data.key] !== "" && <div key={index} >
                                                        <Typography variant="h3" color="blue-gray" className="mt-2 font-normal text-xs md:text-sm">
                                                            {data.value}
                                                        </Typography>
                                                        <Typography variant="h4" color="blue-gray" className="font-bold text-sm md:text-base">
                                                            {cardDetailData[data.key]}
                                                        </Typography>
                                                    </div>}

                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <Typography
                                            variant="h2"
                                            color="blue-gray"
                                            className="font-normal text-base md:text-lg mt-2"
                                        >
                                            {commonItems[lang].marketprice}
                                        </Typography>
                                        <div className="w-full grid grid-cols-3 gap-4">
                                            {dataPriceArray.map((data, index) => (
                                                <React.Fragment key={index}>
                                                    {cardDetailData[data.key] && <div key={index} >
                                                        <Typography variant="h3" color="blue-gray" className="mt-2 font-normal text-xs md:text-sm">
                                                            {data.value}
                                                        </Typography>
                                                        <Typography variant="h4" color="blue-gray" className="font-bold text-sm md:text-base">
                                                            {`$${cardDetailData[data.key]}`}
                                                        </Typography>
                                                    </div>}

                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <Typography
                                            variant="h2"
                                            color="blue-gray"
                                            className="font-normal text-base md:text-lg mt-2"
                                        >
                                            {commonItems[lang]['card-description']}
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            color="blue-gray"
                                            className="whitespace-pre-line font-normal text-sm md:text-base mt-2 leading-6"
                                        >
                                            {
                                                lang === 'zh-tw' && isEnglishOnly
                                                    ? tify(cardDetailData.zh_desc)
                                                    : cardDetailData.desc
                                            }
                                        </Typography>


                                    </div>
                                </> : <div className='col-span-3 bg-white'>
                                        <SkeletonListItem />
                                </div>

                            }
                            <AdsContainer extraClassName='col-span-2 md:col-span-1 mx-auto' maxWidth='w-[300px]' adsSlotID='6251453978' />
                        </div>
                    </section>
                    <section className='my-4'>
                        {cardDetailData &&

                            <Table data={cardDetailData.card_sets} />
                        }
                    </section>

                    <section className='my-4 '>
                        <ProductListSection lang={lang} title={commonItems[lang]['random-card']} data={randomCardData} />
                    </section>



                </div>




            </div>
        </DocumentMeta >
    )
}
